const { getEmptyTableMessage } = require('./utils')

module.exports = {
  addNewRoom: 'Add new room',
  editRoom: 'Edit room',
  searchEmployees: 'Type to search employees',
  chooseService: 'Choose service',
  form: {
    roomName: 'Room name',
    roomSlug: 'Room short name',
    availableOnline: 'Is this room available online?',
    preferredBy: 'Preferred by',
    excludeService: 'Exclude service',
    excludeServiceDescription:
      'Disabling the service for the room reduces available appointments from online registration and available slot search. Additionally, it notifies you of attempts to schedule event with the excluded service',
    comments: '@:common.comments',
    confirmDeleteRoom: 'Are you sure you want to delete this room?',
  },
  branch: 'Branch',
  selectPracticeBranch: '@:common.selectPracticeBranch',
  preferredBy: 'Preferred by',
  excluded: 'Excluded services',
  comments: '@:common.comments',
  online: 'Online',
  confirmDelete:
    'Are you sure you want to delete this room? | Are you sure you want to delete these rooms?',
  eventLossWarning:
    'Upcoming meetings related to this room will be removed, meeting series will end at the current date. | Upcoming meetings related to these rooms will be removed, meeting series will end at the current date.',
  table: {
    empty: getEmptyTableMessage('rooms', '@:rooms.addNewRoom'),
    noPracticeBranch:
      'Practice Branch is not specified, please select the practice branch.',
  },
  searchPlaceholder: '',
  soonToBeAdded: 'Currently under constructions',
  tooltips: {
    thisRoomIsOnline: 'This room is available for online meetings',
    clickToRemoveRoom: 'Click, to remove room',
  },
}
