module.exports = {
  description: {
    bottom:
      'Jeśli zarejestrowałeś już konto w Przelewy24 podaj tutaj parametry Twojego konta, aby przyjmować płatności',
    description: 'Konfiguracja Przelewy24',
  },
  apiKey: 'Klucz API',
  apiKeyDescription:
    'Wpisz tutaj klucz API, który znajdziesz na stronie Przelewów24',
  crcKey: 'Klucz do CRC',
  crcKeyDescription:
    'Wpisz tutaj klucz do CRC, który znajdziesz na stronie Przelewów24',
}
