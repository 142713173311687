module.exports = {
  eg: 'np.',
  mary: 'Maria',
  smith: 'Kowalska',
  marySmith: 'Maria Kowalska',
  marySmithEmailAddress: 'maria.kowalska@poczta.pl',
  marySmithPhoneNumber: '+48 601 345 678',
  dobryGabinet: 'Nazwa gabinetu',
  practiceStreet: 'Wielka',
  placeholderAll: 'Wszystkie możliwości',
  city: 'Warszawa',
  zipCode: '00-123',
  taxNo: '9876543210',
}
