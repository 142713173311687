module.exports = {
  // employee
  employeeUpdated: 'Pracownik został zmieniony',
  employeeCreated: 'Pracownik został utworzony',
  employeeNotCreated: 'Pracownik nie został utworzony',
  employeeInvitationSent: 'Wysłano zaproszenie do pracownika',
  employeeDeactivated: 'Pracownik został wyłączony',
  employeeNotDeactivated: 'Błąd podczas wyłączania pracownika',
  favoriteTherapistsUpdated: 'Ulubieni terapeuci zostali zmienieni',

  // working hours
  workingHoursUpdated: 'Godziny pracy zostały zmienione',

  // meeting
  meetingCreated: 'Spotkanie zostało utworzone',
  meetingUpdated: 'Spotkanie zostało zmienione',
  meetingDeleted: 'Spotkanie zostało usunięte',
  seriesCreated: 'Seria spotkań została utworzona',
  seriesUpdated: 'Seria spotkań została zmieniona',
  seriesDeleted: 'Seria spotkań została usunięta',
  markAsPaid: 'Spotkania zostały oznaczone jako opłacone',
  changedSuccessfully: 'Spotkanie zostało poprawnie zmienione',

  // group member
  groupCreated: 'Grupa została utworzona',
  groupDeleted: 'Grupa została usunięta',
  groupNotCreated: 'Grupa nie została utworzona',

  // group member
  groupMemberAdded: 'Członek grupy został dodany',
  groupMemberRemoved: 'Członek grupy został usunięty',

  // user
  settingsUpdated: 'Twoje ustawienia zostały zmienione',
  avatarSet: 'Zdjęcie zostało ustawione',
  avatarDeleted: 'Zdjęcie zostało usunięte',

  // patient
  patientUpdated: 'Pacjent poprawnie zmieniony',
  patientCreated: 'Pacjent utworzony poprawnie',
  noPatientData: 'Brak danych pacjenta',
  patientDeleted: 'Pacjent został usunięty',
  patientTherapistChanged: 'Terapeuta pacjenta został zmieniony',
  patientTherapyFinished: 'Terapia pacjenta została zakończona',
  patientTherapyReactivated: 'Terapia pacjenta została wznowiona',
  patientAttachmentsUpdated: 'Załączniki pacjenta zostały zmienione',

  // couple
  coupleCreated: 'Para została dodana',
  coupleUpdated: 'Para została zaktualizowana',
  coupleDeleted: 'Para została usunięta',
  coupleTherapyFinished: 'Terapia pary została zakończona',
  coupleTherapyReactivated: 'Terapia pary została wznowiona',

  // guardian
  guardianDeleted: 'Opiekun został usunięty',
  guardianCreated: 'Opiekun został dodany',
  guardianUpdated: 'Opiekun został zmieniony',

  // room
  roomCreated: 'Pokój został stworzony',
  roomUpdated: 'Pokój został zmieniony',
  roomsDeleted: 'Pokój został usunięty | Pokoje zostały usunięte',

  // service
  serviceDeleted: 'Usługa została usunięta',
  serviceCreated: 'Usługa została stworzona',
  serviceUpdated: 'Usługa została zmieniona',
  serviceDeactivated: 'Usługa została wyłączona',
  servicesDeactivated: 'Usługi zostały wyłączone',
  serviceNotCreated: 'Usługa nie została utworzona',
  serviceExludedFromRegistration:
    'Usługa została wyłączona z rejestracji online',
  serviceIncludedInRegistration:
    'Usługa została udostępniona w rejestracji online',

  // psychotherapy / speechtherapy
  notesCreated: 'Notatki zostały dodane',
  notesUpdated: 'Notatki zostały zmienione',

  // practice
  practiceUpdated: 'Ośrodek został zmieniony',
  practiceCreated: 'Ośrodek został stworzony',
  practiceLogoSet: 'Logo ośrodka zostało zmienione',
  practiceLogoDeleted: 'Logo ośrodka zostało usunięte',

  // todo: kinda just for now (until we work with the participants as [] thing)
  //  permissions
  noPermissions: 'Nie masz uprawnień do wykonania tej akcji',

  // attachments
  attachmentDeleted: 'Załącznik został usunięty',

  // gdpr
  gdprConfigUpdated: 'Ustawienia RODO zostały zmienione',
  gdprConsentSent: 'Zgoda RODO została wysłana',

  // invoices
  accountDeletedSuccessfully:
    'Konto usunięte pomyślnie. Nastąpi przeładowanie...',
  accountDeletedFailed: 'Nie udało się usunąć konta',
  accountSuccessfullyChanged: 'Szczegóły konta pomyślnie zmienione',

  // przelewy24
  paymentSent:
    'Twoja prośba zostałą przesłana do P24. Wysłaliśmy do Ciebie email z linkiem, na który musisz klinąć, aby kontynuować rejestrację. Link pojawił się również na górze. Kliknij w link aby kontynuować rejestrację z Przelewy24. ',
  refundSent:
    'Prośba o zwrot została pomyślnie wysłana do P24. Oczekujemy na potwierdzenie zwrócenia środków',
}
