module.exports = {
  description: {
    bottom:
      'If you have already registered account in Przelewy24 enter or modify here your account parameters to accept payments',
    description: 'Przelewy24 configuration',
  },
  apiKey: 'API key',
  apiKeyDescription: 'Enter here your API key, found on the Przelewy24 site',
  crcKey: 'CRC key',
  crcKeyDescription: 'Enter here your CRC key, found on the Przelewy24 site',
}
