module.exports = {
  // employee
  employeeUpdated: 'Employee has been updated',
  employeeCreated: 'Employee has been created',
  employeeNotCreated: 'Employee was not created',
  employeeInvitationSent: 'Employee invitation has been sent',
  employeeDeactivated: 'Employee has been deactivated',
  employeeNotDeactivated: 'Failed to deactivate employee',
  favoriteTherapistsUpdated: 'Favorite Therapists have been updated',

  // working hours
  workingHoursUpdated: 'Working hours have been updated',

  // meeting
  meetingCreated: 'Meeting has been created',
  meetingUpdated: 'Meeting has been updated',
  meetingDeleted: 'Meeting has been deleted',
  seriesCreated: 'Meeting series has been created',
  seriesUpdated: 'Meeting series has been updated',
  seriesDeleted: 'Meeting series has been deleted',
  markAsPaid: 'Meetings have been marked as paid',
  changedSuccessfully: 'Meeting has been successfully changed',

  // group
  groupCreated: 'Group has been created',
  groupDeleted: 'Group has been deleted',
  groupNotCreated: 'Group was not created',

  // group member
  groupMemberAdded: 'Group member has been added',
  groupMemberRemoved: 'Group member has been removed',

  // user
  settingsUpdated: 'Your settings has been updated',
  avatarSet: 'Avatar has been set',
  avatarDeleted: 'Avatar has been deleted',

  // patient
  patientUpdated: 'Patient has been updated',
  patientCreated: 'Patient has been created',
  noPatientData: 'No patient data',
  patientDeleted: 'Patient has been deleted',
  patientTherapistChanged: "Patient's therapist has been changed",
  patientTherapyFinished: "Patient's therapy has been finished",
  patientTherapyReactivated: "Patient's therapy has been reactivated",
  patientAttachmentsUpdated: 'Patient attachments have been updated',

  // couple
  coupleCreated: 'Couple has been created',
  coupleUpdated: 'Couple has been updated',
  coupleDeleted: 'Couple has been deleted',
  coupleTherapyFinished: "Couple's therapy has been finished",
  coupleTherapyReactivated: "Couple's therapy has been reactivated",

  // guardian
  guardianDeleted: 'Guardian has been deleted',
  guardianCreated: 'Guardian has been created',
  guardianUpdated: 'Guardian has been updated',

  // room
  roomCreated: 'Room has been created',
  roomUpdated: 'Room has been updated',
  roomsDeleted: 'Room has been deleted | Rooms have been deleted',

  // service
  serviceDeleted: 'Service has been deleted',
  serviceCreated: 'Service has been created',
  serviceUpdated: 'Service has been updated',
  serviceDeactivated: 'Service has been deactivated',
  servicesDeactivated: 'Services have been deactivated',
  serviceNotCreated: 'Service was not created',
  serviceExludedFromRegistration:
    'Service has been exluded in online registration',
  serviceIncludedInRegistration:
    'Service has been included in online registration',

  // psychotherapy / speech-therapy
  notesCreated: 'Notes have been added',
  notesUpdated: 'Notes have been updated',

  // practice
  practiceUpdated: 'Practice has been updated',
  practiceCreated: 'Practice has been created',
  practiceLogoSet: 'Practice logo has been updated',
  practiceLogoDeleted: 'Practice logo has been deleted',

  // todo: kinda just for now (until we work with the participants as [] thing)
  //  permissions
  noPermissions: "You don't have permission to perform this action",

  // attachments
  attachmentDeleted: 'Attachment has been deleted',

  // gdpr
  gdprConfigUpdated: 'GDPR settings have been updated',
  gdprConsentSent: 'GDPR Consent has been sent',

  // invoices
  accountDeletedSuccessfully:
    'Account deleted successfully. Reload will happen...',
  accountDeletedFailed: 'Account deletion failed',
  accountSuccessfullyChanged: 'Account details successfully changed',

  // przelewy24
  paymentSent:
    'Your request has been sent to P24. We have sent you an email with a link that you must click on to continue registering. The link also appeared at the top. Click on the link to continue registration with Przelewy24.',
  refundSent: 'Refund has been sent by P24. We are waiting on confirmation',
}
