import type { ErrorMap } from '~/types/Graphql'

const map: ErrorMap = {
  error: 'Something went wrong',
  // MUTATIONS
  AddPeriodicEvent: 'Failed to create meeting series',
  AddSingleEvent: 'Failed to create a meeting',
  EditEvent: 'Failed to update the meeting',
  CreateEmployee: 'Failed to create an employee',
  EditEmployee: 'Failed to update an employee',
  // AddGroup: 'Failed to create a group',
  // AddGroupMembers: 'Failed to add group members',
  // DeactivateGroup: 'Failed to deactivate a group',
  AddCouple: 'Failed to create a couple',
  AddPatient: 'Failed to create a patient',
  EditPatient: 'Failed to update a patient',
  AddPatientPsychotherapyNotes:
    "Failed to update patient's psychotherapy notes",
  EditPatientPsychotherapyNotes:
    "Failed to update patient's psychotherapy notes",
  AddPatientSpeechTherapyNotes:
    "Failed to update patient's speech therapy notes",
  EditPatientSpeechTherapyNotes:
    "Failed to update patient's speech therapy notes",
  CreateGuardian: 'Failed to create a guardian',
  DeactivateGuardian: 'Failed to deactivate a guardian',
  EditGuardian: 'Failed to update a guardian',
  EditPractice: 'Failed to update a practice',
  AddRoom: 'Failed to create a room',
  EditRoom: 'Failed to update a room',
  CreateService: 'Failed to create a service',
  EditService: 'Failed to update a service',
  DeactivateServices: 'Failed to deactivate services',
  EditUserSettings: 'Failed to update user settings',
  ConfirmMeetingRequest:
    'Failed to confirm a meeting. The same therapist occupy this slot or this room is taken',
  // QUERIES
  GetEvent: 'Failed to fetch a meeting',
  GetEvents: 'Failed to fetch meetings',
  GetPaginatedEvents: 'Failed to fetch meetings',
  GetEmployeesTable: 'Failed to fetch employees',
  GetGroups: 'Failed to fetch groups',
  GetCouples: 'Failed to fetch couples',
  GetPatients: 'Failed to fetch patients',
  GetPatientDetails: 'Failed to fetch a patient',
  GetCurrentPracticeDetails: 'Failed to fetch practice details',
  GetRoomsList: 'Failed to fetch rooms',
  GetServices: 'Failed to fetch services',
}

export default map
