module.exports = {
  services: 'Services',
  name: '@:common.name',
  addService: 'Add Service',
  saveService: 'Save Service',
  selectDuration: 'Select duration',
  selectAssigned: 'Type to select employee...',
  deleteService: 'Do you want to delete this service?',
  deleteServices: 'Do you want to delete these services?',
  price: 'Price',
  duration: 'Duration',
  comments: 'Comments',
  assignedTo: '@:groups.assignedTo',
  serviceAssignedTo: 'Service assigned to',
  availableOnline: 'Online',
  availableInRegistration: 'Registration',
  isThisServiceAvailableOnline:
    'Is this service available for online meetings?',
  isThisServiceAvailableInOnlineRegistration:
    'Is this service available in online registration?',
  searchPlaceholder: 'Service name, price',
  branch: 'Branch',
  table: {
    selectPracticeBranch: '@:common.selectPracticeBranch',
    empty: 'It seems you have no services yet.',
    emptyOnSearch:
      'It seems you have no services matching your search criteria',
  },
  placeholders: {
    name: 'Therapy',
    comments: 'Long term therapy',
  },
  tooltips: {
    clickToRemoveService: 'Click to delete this service',
    clickToEditService: 'Click to edit this service',
    thisServiceIsOnline: 'This service is provided online',
    serviceIsAvailableInRegistration:
      'This service is available in online registration',
    serviceIsNotAvailableInRegistration:
      'This service is not available in online registration',
  },
}
