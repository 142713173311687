module.exports = {
  title: 'Ośrodek / gabinet',
  address: 'Adres gabinetu',
  contactDetails: 'Dane kontaktowe',
  invoiceData: 'Dane firmy',
  invoiceConfig: 'Ustawienia faktur',
  name: 'Nazwa gabinetu',
  shortName: 'Nazwa skrótowa',
  rooms: 'Pokoje',
  formSteps: {
    name: 'Jak się nazywa Twój gabinet?',
    address: 'Jaki jest adres Twojego gabinetu?',
    phoneAndEmail: 'Jaki jest numer telefonu i adres email Twojego gabinetu?',
  },
  select: {
    youAreViewing: 'Teraz przeglądasz',
    clickToSwitch: 'Kliknij, by przełączyć na',
    clickToSwitchPractice: 'Inne gabinety (kliknij, aby wybrać)',
    clickToAddNewPractice: 'Kliknij aby dodać nowy gabinet zgodnie z cennikiem',
    addNewPractice: 'Dodaj nowy gabinet',
  },
  create: {
    buttons: {
      practiceAddress: 'Adres gabinetu',
      contactDetails: 'Dane kontaktowe',
      nextStep: 'Następny krok',
    },
  },
  logo: {
    image: 'Logo',
    uploadOrDrag: 'Wybierz lub przeciągnij plik',
  },
  branches: {
    all: 'Wszystkie oddziały',
  },
  settings: {
    title: 'Ustawienia gabinetu',
    servicesPerTherapist: 'Widoczność usług dla terapeutów',
    servicesPerTherapistDescription:
      'Włączenie widoczności usług dla terapeutów spowoduje, że terapeuci zobaczą tylko te usługi, do których zostali przypisani. Pracownicy posiadający uprawnienia administratora lub pracownicy recepcji zawsze będą mieli dostęp do wszystkich usług.',
    onlyAdminReception:
      'Tylko pracownicy z uprawnieniami administratora lub recepcji mogą zobaczyć tę stronę',
  },
  invoice: {
    taxNo: '@:invoices.sidebar.preview.taxNo',
    bankAccount: '@:invoices.sidebar.preview.bankAccount',
    bank: '@:invoices.sidebar.preview.bank',
    save: '@:common.save',
    companyName: '@:invoices.sidebar.preview.companyName',
    companyStreet: 'Ulica',
    postCode: 'Kod pocztowy',
    city: 'Miasto',
    country: 'Państwo',
    address: '@:invoices.sidebar.preview.address',
    apiKey: 'Klucz API',
    contractor: 'Firma',
    privatePerson: 'Osoba prywatna',
    invoicesActive: 'Faktury włączone',
    invoicesInactive: 'Faktury wyłączone',
    apiToken: 'Klucz API',
    subdomain: 'Adres podstrony w Fakturowni',
    fullName: 'Imię i nazwisko',
    email: 'Email',
  },
}
