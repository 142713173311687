const {
  getEmptyTableMessage,
  getEmptyTableOnSearchMessage,
} = require('./utils')

module.exports = {
  addGroup: 'Add group',
  groupName: 'Group name',
  startDate: 'Start date',
  finishDate: 'Finish date',
  assignedTo: 'Assigned to',
  typeTherapistNameToSelect: 'Type Therapist name to select',
  searchPlaceholder: 'Search for patient, group',
  menu: {
    finishTherapy: 'Finish therapy',
    history: 'History',
    delete: 'Delete',
    confirmDelete: 'Do you want to delete this group?',
  },
  tabs: {
    meetingHistory: 'Meeting history',
    history: 'History',
    groupMembers: 'Group members',
    attachments: 'Attachments',
    invoices: 'Invoices & payments',
  },
  table: {
    empty: getEmptyTableMessage('groups', 'Add group'),
    emptyOnSearch: getEmptyTableOnSearchMessage('groups'),
  },
}
