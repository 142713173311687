module.exports = {
  employees: 'Pracownicy',
  addEmployee: 'Dodaj pracownika',
  acronym: 'Akronim',
  details: 'Szczegóły',
  roles: 'Rola',
  color: 'Kolor',
  fullName: 'Imię i nazwisko',
  name: 'Imię',
  onlineReg: 'Rej. online',
  email: 'Email',
  surname: 'Nazwisko',
  phoneNumber: 'Numer telefonu',
  practiceBranch: 'Oddział',
  preferredRooms: 'Preferowane pokoje',
  selectPracticeBranch: 'Wybierz oddział',
  workingTime: 'Czas pracy',
  all: 'Wszyscy',
  searchPlaceholder: 'Imię lub nazwisko osoby',
  employeeData: 'Dane pracownika',
  availabilitySettings: 'Ustawienia dostępności',
  invitationTitle:
    "Zostałaś/-eś zaproszona/-y do dołączenia do ośrodka <span class='text-primary'>{practiceName}</span>",
  invitationEmailWarning: `
    Zaproszenie było wysłane na adres <b>{invitedEmail}</b> podczas gdy Ty używasz adresu
    <b>{actualEmail}</b
    >. Jeśli to pomyłka wciśnij proszę przycisk
    <b>"Odrzuć zaproszenie"</b> i zaloguj się ponownie z poprawnym adresem e-mail.`,
  acceptInvitation: 'Przyjmij zaproszenie',
  declineInvitation: 'Odrzuć zaproszenie',
  rolesEnum: {
    adm: 'Administrator | Administratorzy',
    rec: 'Recepcja',
    emp: 'Pracownik | Pracownicy',
    tech: 'Personel techniczny',
    fin: 'Finanse',
  },
  editAvailability: 'Edytuj dostępność',
  table: {
    empty:
      'Brakuje pracowników, proszę dodaj używając przycisku Dodaj Pracownika.',
    emptyOnSearch:
      'Nie znaleziono pracowników odpowiadających Twoim kryteriom.',
  },
  backToEmployeesList: 'Wróć do listy pracowników',
  tooltips: {
    clickAvailability: 'Kliknij, aby ustawić czas pracy',
    editEmployee: 'Edytuj pracownika',
    resendInvitation: 'Ponownie wyślij zaproszenie',
    accountConfirmed: 'Konto potwierdzone',
    clickToRemoveEmployee: 'Kliknij, aby usunąć pracownika',
  },
  form: {
    selectPreferredRooms: 'Wybierz preferowane pokoje',
    selectPracticeBranchFirst: 'Najpierw wybierz oddział',
    selectRoles: 'Określ funkcję',
    confirmDeleteEmployee: 'Chcesz usunąć tego pracownika?',
  },
  availableInOnlineRegistration: {
    title: 'Ta osoba jest dostępna do rezerwacji online',
    description:
      'Jeśli tego nie zaznaczysz pacjenci nie będą mogli umawiać się do tej osoby',
  },
  clickToDeleteThisPerson: {
    title: 'Kliknij, aby usunąć tę osobę',
    description:
      'Usunięta osoba nie będzie już miała dostępu do Twoich danych i Twojego ośrodka',
  },
  holidays: {
    title: 'Twoje plany urlopowe',
    description: `Poniżej znajdziesz listę Twoich nadchodzących urlopów/dni wolnych. W tym
    czasie kalendarz zostanie zablokowany i nie będzie można umawiać do
    Ciebie spotkań. Umówione już spotkania zostaną odwołane. Jeśli masz
    włączone SMSy system poinformuje o tym pacjentów z ustawionym przez
    Ciebie wyprzedzeniem`,
    statusTooltip: 'Zatwierdzone przez {by} {on}',
    awaitingApproval: 'Oczekuje na zatwierdzenie',
    restLeave: 'Urlop wypoczynkowy',
    sickLeave: 'Zwolnienie lekarskie',
    dialog: {
      title: 'Dodaj urlop',
      selectDateOrRange: 'Wybierz datę lub zakres urlopu',
      note: 'Notatka do prośby',
      notePlaceholder: 'Tutaj możesz wpisać dodać komentarz do prośby',
      infoContent:
        'Uwaga - dodanie urlopu odwoła zaplanowane w tym czasie spotkania. Jeśli masz włączone SMSy pacjenci dostaną o tym powiadomienie zgodnie z ustawionym wyprzedzeniem. ',
      selectType: 'Wybierz typ urlopu',
    },
    type: {
      restLeave: 'Urlop wypoczynkowy',
      sickLeave: 'Zwolnienie lekarskie',
      other: 'Inny',
    },
  },
}
