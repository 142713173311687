import { computed, ref, useStore } from '@nuxtjs/composition-api'
import dayjs from 'dayjs'
import {
  BillingInfoInput,
  GetPatientInvoiceDataQueryVariables,
  InvoiceLineItem,
} from '~/generated/graphqlDjango'
import { DropdownElement } from '~/types/DropdownElement'
import { DeepPartial } from '~/types/general'
import {
  InvoiceForm,
  InvoiceInfoForm,
  InvoicePaymentForm,
  TableInvoiceLineItem,
} from '~/types/Invoice'

const patientQueryVariables = ref<GetPatientInvoiceDataQueryVariables>({
  patientId: -1,
  after: dayjs().startOf('month').format('YYYY-MM-DD'),
  before: dayjs().format('YYYY-MM-DD'),
})

export const useInvoiceFormStore = () => {
  const store = useStore()

  const invoiceForm = computed<DeepPartial<InvoiceForm>>(
    () => store.getters['invoices/getInvoiceForm']
  )

  const invoiceLineItems = computed<Partial<TableInvoiceLineItem>[]>(
    () => store.getters['invoices/getInvoiceLineItems']
  )

  const invoiceRecipient = computed(
    () => store.getters['invoices/getInvoiceRecipient']
  )

  const invoicePayment = computed(
    () => store.getters['invoices/getInvoicePayment']
  )

  const invoiceInfo = computed(() => store.getters['invoices/getInvoiceInfo'])

  const selectedPatient = computed(
    () => store.getters['invoices/getSelectedPatient']
  )

  const invoiceCustomItems = computed(
    () => store.getters['invoices/getCustomItems']
  )

  const invoiceItems = computed(() => store.getters['invoices/getItems'])

  const isSelectInvoiceRecipientButtonVisible = computed<boolean>(
    () => store.getters['invoices/getSelectInvoiceRecipient']
  )

  const setInvoiceForm = (invoiceData: DeepPartial<InvoiceForm>) => {
    store.dispatch('invoices/transformAndSetInvoiceForm', invoiceData)
  }

  const setSelectInvoiceRecipient = (selectInvoiceRecipient: boolean) => {
    store.commit(
      'invoices/setInvoiceRecipientButtonState',
      selectInvoiceRecipient
    )
  }

  const setRecipient = (invoiceRecipient: BillingInfoInput) => {
    store.commit('invoices/setRecipient', invoiceRecipient)
  }

  const setSelectedPatient = (selectedPatient: DropdownElement | null) => {
    store.commit('invoices/setSelectedPatient', selectedPatient)

    if (selectedPatient && selectedPatient.code)
      patientQueryVariables.value.patientId = Number(selectedPatient.code)
  }

  const setInvoiceInfo = (invoiceInfo: InvoiceInfoForm) => {
    store.commit('invoices/setInvoiceInfo', invoiceInfo)
  }

  const setInvoicePayment = (invoicePayment: InvoicePaymentForm) => {
    store.commit('invoices/setInvoicePayment', invoicePayment)
  }

  const setLineItems = (invoiceCustomItems: Partial<InvoiceLineItem>[]) => {
    store.commit('invoices/setLineItems', invoiceCustomItems)
  }

  const setCustomItems = (
    invoiceCustomItems: Partial<TableInvoiceLineItem>[]
  ) => {
    store.commit('invoices/setInvoiceCustomItems', invoiceCustomItems)
  }

  const setCustomItem = (data: {
    invoiceCustomItem: TableInvoiceLineItem
    index: number
  }) => {
    store.commit('invoices/setInvoiceCustomItem', data)
  }

  const setItem = (data: {
    invoiceCustomItem: TableInvoiceLineItem
    index: number
  }) => {
    store.commit('invoices/setInvoiceItem', data)
  }

  const setItems = (invoiceItems: Partial<TableInvoiceLineItem>[]) => {
    store.commit('invoices/setInvoiceItems', invoiceItems)
  }

  return {
    invoiceForm,
    invoiceItems,
    invoiceRecipient,
    invoiceLineItems,
    setInvoiceForm,
    setRecipient,
    isSelectInvoiceRecipientButtonVisible,
    setSelectInvoiceRecipient,
    invoiceCustomItems,
    setCustomItems,
    setItems,
    setItem,
    setCustomItem,
    setInvoiceInfo,
    setLineItems,
    invoiceInfo,
    setSelectedPatient,
    selectedPatient,
    setInvoicePayment,
    invoicePayment,
    patientQueryVariables,
  }
}
