import { QuickMeetingModalState } from '../types/navBar'

export const state = (): QuickMeetingModalState => ({
  isQuickMeetingModalVisible: false,
})

export const mutations = {
  changeQuickMeetingVisibility(
    state: { isQuickMeetingModalVisible: boolean },
    visibility: boolean
  ) {
    state.isQuickMeetingModalVisible = visibility
  },
}

export const getters = {
  getQuickMeeting: (state: QuickMeetingModalState) =>
    state.isQuickMeetingModalVisible,
}
