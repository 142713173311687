module.exports = {
  RODO: 'RODO',
  yes: 'TAK',
  no: 'NIE',
  settings: 'Ustawienia',
  automaticEmails:
    'Przy dodaniu nowego pacjenta system będzie automatycznie wysyłał maila z pytaniem o zgodę RODO.',
  automaticEmailsDescription:
    "Jeżeli zaznaczysz opcję 'TAK' przy tworzeniu pacjenta, który posiada wprowadzony adres email, system automatycznie wyśle wiadomość email z linkiem do polityki RODO. Po kliknięciu na ten link pacjent zostanie przekierowany na stronę z polityką RODO, gdzie będzie mógł ją zatwierdzić. Informacje o zatwierdzeniu zgody przez pacjenta można sprawdzić w panelu pacjenta.",
  automaticEmailsDescription2:
    "Jeżeli zaznaczysz opcję 'NIE', będziesz mógł ręcznie wysłać zgody do każdego pacjenta. Opcje wysyłki znajdziesz przy informacjach dotyczących danego pacjenta.",
  rodoPracticeBranch:
    'Wysyłana zgoda na przetwarzanie danych będzie dostosowana dla gabinetu/ośrodka',
  rodoPracticeBranchDescription:
    "Wybierz opcję, która najlepiej opisuje Twój gabinet/ośrodek. Jeśli w Twoim ośrodku pracuje kilku specjalistów, wybierz opcję, która jest Ci najbliższa. Jeśli żadna z opcji nie pasuje lub Twój ośrodek jest na tyle interdyscyplinarny, wybierz opcję: 'Generyczne'",
  rodoPracticeBranchDescription2:
    'Co zawiera zgoda? Informuje pacjentów, ze Twój gabinet/ośrodek jest administratorem danych, a także o celach przetwarzania danych. Dodatkowo informuje pacjentów o prawach, jakie im przysługują w kontekście ich danych osobowych.',
  tooltips: {
    signed: 'Podpisane: ',
    notSigned: 'Niepodpisane',
  },
  SPEECH_THERAPIST: 'Logopedyczne',
  PSYCHOTHERAPIST: 'Psychoterapeutyczne',
  GENERIC: 'Generyczne',
  OTHER: 'Inne',
  adult: 'Dorosły',

  // child
  child: 'Dziecko',
  SPEECH_THERAPIST_CHILD: 'Dziecko - Logopedyczne',
  PSYCHOTHERAPIST_CHILD: 'Dziecko - Psychoterapeutyczne',
  GENERIC_CHILD: 'Dziecko - Generyczne',
  OTHER_CHILD: 'Dziecko - Inne',

  gdprPages: {
    adult: {
      footer: ', wyrażam zgodę na przetwarzanie moich danych osobowych przez',
      generic: {
        title1: 'Zgoda na przetwarzanie danych osobowych',
        title2: 'w związku z terapią lub konsultacją',
        p11: 'W związku z tym, że podczas terapii lub konsultacji będą zbierane informacje o szczególnym, prywatnym charakterze, proszę o wyrażenie zgody na przetwarzanie Pana/Pani danych osobowych',
        p12: 'Niewyrażenie zgody uniemożliwi prawidłowe przeprowadzenie terapii lub konsultacji. Wyrażona zgoda może być w każdej chwili cofnięta, jednak cofnięcie zgody nie wpłynie na zgodność z prawem przetwarzania, które miało miejsce przed jej wycofaniem.',
        p211: 'Administratorem Pan/Pani danych osobowych',
        p212: 'jest',
        p22: ', u którego dostępne są szczegółowe informacje na temat przetwarzania Pana/Pani danych osobowych.',
        p31: 'Jeżeli zgadza się Pan/Pani na przetwarzanie przez Administratora danych osobowych niezbędnych do przeprowadzenia terapii lub konsultacji, w tym danych dotyczących stanu zdrowia i innych okoliczności dotyczących Pana/Pani życia prywatnego',
        p32: 'mających znaczenie dla prowadzonej terapii lub konsultacji, proszę kliknąć poniżej.',
      },
      psychotherapist: {
        title1: '@:gdpr.gdprPages.adult.generic.title1',
        title2: 'w związku z psychoterapią lub konsultacją psychologiczną',
        p11: 'W związku z tym, że podczas psychoterapii lub konsultacji psychologicznej będą zbierane informacje o szczególnym, prywatnym charakterze, proszę o wyrażenie zgody na przetwarzanie Pana/Pani danych osobowych',
        p12: 'Niewyrażenie zgody uniemożliwi prawidłowe przeprowadzenie terapii lub konsultacji. Wyrażona zgoda może być w każdej chwili cofnięta, jednak cofnięcie zgody nie wpłynie na zgodność z prawem przetwarzania, które miało miejsce przed jej wycofaniem.',
        p211: '@:gdpr.gdprPages.adult.generic.p211',
        p212: '@:gdpr.gdprPages.adult.generic.p212',
        p22: '@:gdpr.gdprPages.adult.generic.p22',
        p31: 'Jeżeli zgadza się Pan/Pani na przetwarzanie przez Administratora danych osobowych niezbędnych do przeprowadzenia terapii, w tym danych dotyczących stanu zdrowia, życia rodzinnego i innych okoliczności dotyczących Pana/Pani życia prywatnego',
        p32: 'mających znaczenie dla prowadzonej terapii lub konsultacji, proszę kliknąć poniżej.',
      },
      speech: {
        title1: '@:gdpr.gdprPages.adult.generic.title1',
        title2: 'w związku z terapią lub konsultacją logopedyczną',
        p11: 'W związku z tym, że podczas terapii lub konsultacji logopedycznej będą zbierane informacje o szczególnym, prywatnym charakterze, proszę o wyrażenie zgody na przetwarzanie Pana/Pani danych osobowych',
        p12: 'Niewyrażenie zgody uniemożliwi prawidłowe przeprowadzenie terapii lub konsultacji. Wyrażona zgoda może być w każdej chwili cofnięta, jednak cofnięcie zgody nie wpłynie na zgodność z prawem przetwarzania, które miało miejsce przed jej wycofaniem.',
        p211: '@:gdpr.gdprPages.adult.generic.p211',
        p212: '@:gdpr.gdprPages.adult.generic.p212',
        p22: '@:gdpr.gdprPages.adult.generic.p22',
        p31: ' Jeżeli zgadza się Pan/Pani na przetwarzanie przez Administratora danych osobowych niezbędnych do przeprowadzenia terapii lub konsultacji logopedycznej, w tym danych dotyczących stanu zdrowia, życia rodzinnego, nagrań mowy, zdjęć (np. twarzy, jamy ustnej, zgryzu etc.) i innych okoliczności dotyczących Pana/Pani życia prywatnego',
        p32: 'mających znaczenie dla prowadzonej konsultacji lub terapii, proszę kliknąć poniżej.',
      },
    },
    child: {
      footer: 'Wyrażam zgodę na przetwarzanie danych podopiecznego przez',
      generic: {
        title1: 'Zgoda na przetwarzanie danych osobowych podopiecznego',
        title2: '@:gdpr.gdprPages.adult.generic.title2',
        p11: 'W związku z tym, że podczas terapii lub konsultacji będą zbierane informacje o szczególnym, prywatnym charakterze, proszę o wyrażenie zgody na przetwarzanie danych osobowych Pana/Pani podopiecznego',
        p12: 'Niewyrażenie zgody uniemożliwi prawidłowe przeprowadzenie terapii lub konsultacji. Wyrażona zgoda może być w każdej chwili cofnięta, jednak cofnięcie zgody nie wpłynie na zgodność z prawem przetwarzania, które miało miejsce przed jej wycofaniem.',
        p211: 'Administratorem danych osobowych podopiecznego',
        p212: 'jest',
        p22: ', u którego dostępne są szczegółowe informacje na temat przetwarzania danych osobowych.',
        p31: 'Jeżeli zgadza się Pan/Pani na przetwarzanie przez Administratora danych osobowych niezbędnych do przeprowadzenia terapii lub konsultacji, w tym danych dotyczących stanu zdrowia i innych okoliczności dotyczących życia prywatnego Pana/Pani podopiecznego',
        p32: 'mających znaczenie dla prowadzonej terapii lub konsultacji, proszę kliknąć poniżej.',
      },
      psychotherapist: {
        title1: '@:gdpr.gdprPages.child.generic.title1',
        title2: '@:gdpr.gdprPages.adult.psychotherapist.title2',
        p11: 'W związku z tym, że podczas psychoterapii lub konsultacji psychologicznej będą zbierane informacje o szczególnym, prywatnym charakterze, proszę o wyrażenie zgody na przetwarzanie danych osobowych Pana/Pani podopiecznego',
        p12: 'Niewyrażenie zgody uniemożliwi prawidłowe przeprowadzenie terapii lub konsultacji. Wyrażona zgoda może być w każdej chwili cofnięta, jednak cofnięcie zgody nie wpłynie na zgodność z prawem przetwarzania, które miało miejsce przed jej wycofaniem.',
        p211: '@:gdpr.gdprPages.child.generic.p211',
        p212: '@:gdpr.gdprPages.child.generic.p212',
        p22: '@:gdpr.gdprPages.child.generic.p22',
        p31: 'Jeżeli zgadza się Pan/Pani na przetwarzanie przez Administratora danych osobowych niezbędnych do przeprowadzenia terapii, w tym danych dotyczących stanu zdrowia, życia rodzinnego i innych okoliczności dotyczących życia prywatnego Pana/Pani podopiecznego',
        p32: 'mających znaczenie dla prowadzonej terapii lub konsultacji, proszę kliknąć poniżej.',
      },
      speech: {
        title1: '@:gdpr.gdprPages.child.generic.title1',
        title2: '@:gdpr.gdprPages.adult.speech.title2',
        p11: 'W związku z tym, że podczas terapii lub konsultacji logopedycznej będą zbierane informacje o szczególnym, prywatnym charakterze, proszę o wyrażenie zgody na przetwarzanie danych osobowych Pani/Pana podopiecznego',
        p12: 'Niewyrażenie zgody uniemożliwi prawidłowe przeprowadzenie terapii lub konsultacji. Wyrażona zgoda może być w każdej chwili cofnięta, jednak cofnięcie zgody nie wpłynie na zgodność z prawem przetwarzania, które miało miejsce przed jej wycofaniem.',
        p211: '@:gdpr.gdprPages.child.generic.p211',
        p212: '@:gdpr.gdprPages.child.generic.p212',
        p22: '@:gdpr.gdprPages.child.generic.p22',
        p31: 'Jeżeli zgadza się Pan/Pani na przetwarzanie przez Administratora danych osobowych niezbędnych do przeprowadzenia terapii lub konsultacji logopedycznej, w tym danych dotyczących stanu zdrowia, życia rodzinnego, nagrań mowy, zdjęć (np. twarzy, jamy ustnej, zgryzu etc.) i innych okoliczności dotyczących życia prywatnego podopiecznego',
        p32: 'mających znaczenie dla prowadzonej konsultacji lub terapii, proszę kliknąć poniżej.',
      },
    },
  },
}
