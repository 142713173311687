module.exports = {
  // markServicesAsAvailable:
  //   'Aby twoje usługi były widoczne dla potencjalnych pacjentów, oznacz je jako widoczne w rejestracji online.',
  meetingHasBeenCreated: 'Spotkanie zostało utworzone',
  meetingHasBeenRejected: 'Spotkanie zostało odrzucone',
  meetingHasBeenDeleted: 'Spotkanie zostało usunięte',
  clickHereToGoToServices: 'Kliknij tutaj, aby przejść do widoku usług.',
  clickToCopy: 'Kliknij by skopiować link',
  createMeeting: 'Potwierdź spotkanie',
  createNewPatient: 'Utwórz nowego pacjenta',
  meetingRequest: 'Potwierdź rezerwację spotkania',
  meetingRequests: 'Rezerwacje do potwierdzenia',
  minutesBetweenMeetings: 'Liczba minut przerwy między spotkaniami',
  minutesBetweenMeetingsDescription:
    'Ta liczba minut zostanie doliczona po spotkaniu zanim kolejne spotkanie będzie możliwe ',
  noMeetingRequests:
    'Aktualnie nie masz nowych spotkań do potwierdzenia. Kiedy pacjenci będą chcieli umówić się na wizytę, ich prośby pojawią się tutaj.',
  noMeetingRequestsHeader: 'Brak nowych spotkań do potwierdzenia.',
  przelewy24ConfigUpdated: 'Konfiguracja Przelewów24 została zaktualizowana',
  patient: 'Pacjent',
  pickRoom: 'Wybierz pokój',
  practiceSlug: 'Nazwa Twojego ośrodka w adresie formularza rejestracji',
  privacyPolicy: 'Link do Polityki Prywatności',
  registrationEnabled: 'Status rejestracji online',
  registrationInfo:
    'Po włączeniu rejestracji online Twoi pacjenci będą mogli umawiać się na spotkania z Tobą przez formularz dostępny online. Wybierz również usługi dostępne do rejestracji online oznaczając je odpowiednio w ',
  rememberToSetWorkingTimes:
    'Pamiętaj o określeniu czasu pracy dla każdej osoby, która ma być dostępna w rejestracji online. Bez tego dana osoba nie będzie widoczna dla pacjentów. Możesz to zrobić w ustawieniach pracowników.',
  serviceSettings: 'ustawieniach usług.',
  registrationLink: 'Link do rejestracji',
  room: 'Pokój',
  sureToConfirmMeeting: 'Czy potwierdzasz spotkanie:',
  termsOfUse: 'Link do Regulaminu',
  thereAreSimilarPatients:
    'W Twojej kartotece istnieje już pacjent o podanych danych. Wybierz istniejącego pacjenta lub pozostaw to pole puste, aby dodać nowego.',
  title: 'Rejestracja online',
  configUpdated: 'Ustawienia rejestracji online zostały zaktualizowane',
  enabled: 'Rejestracja online jest włączona',
  disabled: 'Rejestracja online jest wyłączona',
  registrationUrl: 'Adres Twojego formularza rejestracji dla pacjentów',
  isCancelledMeetingFreeSlot:
    'Czy odwołane spotkanie powinno być ponownie udostępnione jako dostępny termin w systemie rejestracji online dla innych pacjentów?',
  isCancelledMeetingFreeSlotBottomText:
    'Przy wybraniu „Nie” kalendarz wolnych terminów nie pokaże terminów odwołanych spotkań dla nowych pacjentów.',
  isCancelledMeetingFreeSlotTitle: 'Ustawienia odwołań',
  isCancelledMeetingFreeSlotLeftDescription:
    'Określ jak system ma postępować w przypadku odwołanych spotkań.',
  clickToCopyRegistrationUrl:
    'Kliknij, aby przejśc pod dany adres. Możesz również skopiować i wkleić na Twoją stronę pod przyciskiem “Umów spotkanie"',
  onlineRegistrationStatusTitle: 'Status rejestracji online',
  onlineRegistrationStatusDescription:
    'Tutaj możesz ustawić czy i pod jakim adresem będziesz udostępniać rejestrację online.',
  timeSettingsTitle: 'Ustawienia czasu',
  timeSettingsDescription:
    'Tutaj możesz określić wyprzedzenie przed i po spotkaniu ',
  termsSettingsTitle: 'Ustawienia regulaminów',
  termsSettingsDescription:
    'Tutaj możesz wkleić link do regulaminu rejestracji online i Twojej Polityki Prywatności, które zobaczą klienci w rejestracji online. ',
  parseTermsOfUseLinkHere:
    'Wklej tutaj link regulaminu rejestracji online, który jest dostępny na Twojej stronie. ',
  parsePrivacyPolicyLinkHere:
    'Wklej tutaj link do Twojej Polityki Prywatności, która jest dostępna na Twojej stronie. ',
  registrationLinkCopied: 'Skopiowano link do rejestracji online',
  minimumPrecedingMinutes: 'Minimalne wyprzedzenie przed spotkaniem',
  minimumPrecedingMinutesDescription:
    'Minimalne wyprzedzenie z jakim pacjenci mogą się rejestrować. Np. jeśli wyprzedzenie wynosi 8h to pacjenci będą mogli się umówić dopiero za 8 godzin licząc od aktualnej godziny. Dla jednego i dwóch dni, będą pokazywały się terminy zaczynające się od rana.',
  maximumPrecedingMinutes: 'Maksymalne wyprzedzenie dla spotkań',
  maximumPrecedingMinutesDescription:
    'Określ maksymalny dostępny okres, na jaki pacjenci mogą umawiać z wyprzedzeniem spotkania.',
  exactSlot: 'Zaokrąglij początek nowych spotkania',
  exactSlotDescription:
    'Proponuj nowe spotkania z zaokrągleniem do podanej wartości',
  roundSlotTimeTo: 'Proponuj nowe spotkanie z odstępami co',
  roundToDescription:
    'Ta liczba minut ustala momenty rozpoczęcia potencjalnych spotkań. Uwzględnia godziny prac terapeutów i zapisane spotkania w kalendarzu.',
  quarter: 'Kwadrans',
  preciseSearch: 'Szukanie precyzyjne',
  preciseSearchDescription:
    'Opcja znajduje maksymalną liczbę wolnych terminów w kalendarzu poprzez wyłączenie zaokrągleń w niektórych przypadkach. Może skutkować mniej standardowymi godzinami np. 10:47 zamiast 10:50',
  halfHour: 'Pół godziny',
  hour: 'Godzina',
  none: 'Brak',
  twoHours: 'Dwie godziny',
  threeHours: 'Trzy godziny',
  fourHours: 'Four hours',
  fiveHours: 'Five hours',
  details: 'Szczegóły',
  reject: 'Odrzuć',
  sureToRejectMeeting: 'Czy na pewno chcesz odrzucić to spotkanie?',
  sendSMSUponRejectMeeting: 'Wyślij SMS do pacjenta o odwołaniu spotkania',
  createdAt: 'Prośba z dnia',
  confirmInfo:
    'Po zaakceptowaniu spotkania, zostanie ono automatycznie dodane do Twojego kalendarza. Jednocześnie prześlemy potwierdzenie do pacjenta.',
  notSpecified: 'Nieokreślony',
  day: '{count} dzień |{count} dni',
  logoSet: 'Logo ustawione',
  logoRegistrationUpload: 'Logo formularza',
  logoRegistrationUploadDescription:
    'Logo widoczne na formularzu. Jest to to samo logo widoczne w ustawieniach.',

  tabs: {
    reservations: 'Rezerwacje',
    settings: 'Ustawienia',
    payments: 'Płatności',
    registration: 'Rejestracja w Przelewy24',
  },

  // Maximum Proceeding Options
  oneWeek: 'Jeden tydzień',
  twoWeeks: 'Dwa tygodnie',
  threeWeeks: 'Trzy tygodnie',
  fourWeeks: 'Cztery tygodnie',
  fiveWeeks: 'Pięć tygodni',
  sixWeeks: 'Sześć tygodni',
  sevenWeeks: 'Siedem tygodni',
  eightWeeks: 'Osiem tygodni',
  nineWeeks: 'Dziewięć tygodni',
  tenWeeks: 'Dziesięć tygodni',
  elevenWeeks: 'Jedenaście tygodni',
  twelveWeeks: 'Dwanascie tygodni',
}
