module.exports = {
  title: 'Practice reports',
  reportWillBeSent: 'Report will be sent to your email',
  comparisonText:
    'Comparison is shown between: {previousStart} - {previousEnd} and {currentStart} - {currentEnd}',
  dashboard: {
    title: 'Dashboard',
    income: 'Income',
    dynamicsDescription:
      'Income in the selected time period compared to previous time period',
    totalPlannedIncome: {
      title: 'Total planned income',
      description: 'Income coming from all planned sessions',
    },
    paidServices: {
      title: 'Paid services',
      description: 'So far paid out income',
    },
    unpaidMeetings: {
      title: 'Unpaid meetings',
      description: 'The income yet to be paid out / collected',
    },
    lostIncome: {
      title: 'Lost income',
      description: 'Income lost due to cancelled meetings',
    },
  },
  services: {
    title: 'Services breakdown',
    meetings: {
      meetingsHeld: {
        title: 'Number of held meetings',
        description: 'The number of held meetings in the selected time period',
      },
      meetingsScheduled: {
        title: 'Number of scheduled meetings',
        description:
          'The number of scheduled meetings in the selected time period',
      },
      meetingsCancelled: {
        title: 'Cancelled meetings',
        description: 'Number of cancelled meetings in the selected period.',
      },
      patientsNumber: {
        title: 'Number of patients',
        description: 'Number of different patients in the selected time period',
      },
      therapiesFinished: {
        title: 'Therapies finished',
        description: 'Number of  therapies finished in this period.',
      },
      therapiesStarted: {
        title: 'New therapies started',
        description: 'Number of  new therapies started in this period.',
      },
    },
    breakdown: {
      title: 'Services breakdown',
      currentPeriod: 'Current period',
      previousPeriod: 'Previous period',
      table: {
        empty: 'There are no services in this period',
      },
    },
  },
  employees: {
    title: 'Employees breakdown',
    chooseEmployee: 'Choose employee',
    heldMeetingsBreakdown: 'Held meetings breakdown',
    download: 'Download',
    overview: {
      meetingsScheduled: {
        title: 'Number of scheduled meetings',
        description:
          'The number of scheduled meetings in the selected time period',
      },
      meetingsHeld: {
        title: 'Number of held meetings',
        description: 'The number of held meetings in the selected time period',
      },
      meetingsCancelled: {
        title: 'Number of cancelled meetings',
        description: 'Number of cancelled meetings in the selected period. ',
      },
      heldMeetingsRate: {
        title: 'Held meetings rate',
        description: 'Percentage of held meetings vs. scheduled meetings',
      },
    },
  },
  servicesBreakdown: {
    name: 'Service name',
    meetingsHeld: 'No. of held events',
    meetingsDuration: 'Time',
    meetingsIncome: 'Amount',
    meetings: {
      patient: 'Patient',
      date: 'Date',
      price: 'Amount',
      status: 'Status',
      emptyDesc: 'Change filters to any records',
    },
  },
  table: {
    empty: 'No records for specified filters',
  },
}
