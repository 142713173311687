module.exports = {
  addQuickNote: 'Add quick note',
  createDocument: 'Create document for patient',
  title: 'Meeting history',
  couplesTitle: 'History',
  downloadNotes: 'Download Notes',
  downloadReport: 'Click to download PDF report',

  from: 'From',
  until: 'Until',
  noSessions:
    'No appointments in the selected period. Add an appointment to the calendar to see it here.',
  noSessionsContinuing:
    'Here you will only see historical encounters. Upcoming meetings are shown on the right side.',
  date: 'Date',
  service: 'Service',
  therapist: 'Therapist',
  actions: 'Actions',

  filters: {
    date: 'Date',
    service: 'Service',
    status: 'Status',
    therapist: 'Therapist',
    note: 'Note',
    options: {
      clear: 'Clear',
      asc: 'Ascending',
      desc: 'Descending',
      withNotes: 'With notes',
      withoutNotes: 'Without notes',
      withAttachments: 'With attachments',
      withoutAttachments: 'Without attachments',
    },
    placeholders: {
      service: 'Input service name',
      therapist: 'Input therapist name',
      status: 'Select status',
    },
  },
  row: {
    quickNote: 'Quick note',
    document: 'Document',
    statuses: {
      paid: 'Paid',
      unpaid: 'Unpaid',
      cancelled: 'Cancelled',
      patientCancelledPayable: 'Cancelled, payable',
      patientCancelledNotPayable: 'Cancelled, not-payable',
      meetingCancelledByMe: 'Cancelled by me',
    },
    options: {
      download: 'Download',
      edit: 'Edit',
      markAsRead: 'Mark as paid',
      issueInvoice: 'Issue invoice',
    },
  },
  tooltips: {
    download: 'Click to download notes from this period',
    filter: 'Click to filter notes',
    noSessions: 'There are no sessions to download notes from',
    aNoteHasBeenAddedToThisMeeting: 'A note has been added to this meeting',
    clickToOpenOptions: 'Click to see options',
  },
  files: {
    txtName: 'Session - note',
    singleZipName: 'Note_from_patients_session.zip',
    multiZipName: 'Notes_from_all_patients_sessions.zip',
  },
}
