module.exports = {
  fileSizeMore5MB: 'Rozmiar pliku przekracza 5 MB',
  selectAndClickToTrigger: 'Wybierz element(y) i kliknij, aby uruchomić akcję',
  addedOn: 'Dodano:',
  yes: 'Tak',
  no: 'Nie',
  new: 'Nowy',
  view: 'Widok',
  edit: 'Edytuj',
  cancel: 'Anuluj',
  selected: 'Wybrano',
  delete: 'Usuń',
  fullName: 'Imię i Nazwisko',
  details: 'Uwagi',
  roles: 'Funkcje',
  color: 'Kolor',
  download: 'Pobierz',
  copiedSuccessfully: 'Skopiowano pomyślnie',
  // nazwa
  name: 'Nazwa',
  slug: 'Skrót',
  tooltip: {
    maxCoupleWarning:
      'Do pary można dodać maksymalnie 2 pacjentów! Jeśli potrzebujesz więcej, rozważ utworzenie grupy!',
    findAFreeSlot: 'Znajdź wolny termin',
  },
  // imię
  firstName: 'Imię',
  email: 'Email',
  surname: 'Nazwisko',
  birthday: 'Data urodzenia',
  idNumber: 'Numer dokumentu tożsamości',
  print: 'Drukuj',
  phoneNumber: 'Numer telefonu',
  phoneNumber2: 'Numer telefonu nr 2',
  addNew: 'Dodaj nowe',
  sendSmsTo: 'Wyślij SMS do',
  phone: 'Telefon',
  added: 'Dodano',
  ended: 'Zakończone',
  filters: 'Filtry',
  location: 'Oddział',
  assignedTo: 'Pacjent przypisany do',
  search: 'Szukaj',
  address: 'Adres',
  notAvailableYet: 'Dostępne już wkrótce!',
  selectPracticeBranch: 'Wybierz oddział',
  saveChanges: 'Zapisz zmiany',
  save: 'Zapisz',
  sendInvitation: 'Wyślij zaproszenie',
  nextStep: 'Następny krok',
  comments: 'Uwagi',
  active: 'Aktywni',
  inactive: 'Nieaktywni',
  profile: 'Profil',
  chooseLanguage: 'Wybierz swój język',
  noResults: 'Brak wyników',
  noOptions: 'Brak dostępnych opcji',
  photo: 'Zdjęcie',
  remove: 'Usuń',
  change: 'Zmień',
  from: 'Od',
  until: 'Do',
  copyToClipboard: 'Skopiuj do schowka',
  copiedToClipboard: 'Skopiowano do schowka',
  shortMonths: {
    jan: 'Sty',
    feb: 'Lut',
    mar: 'Mar',
    apr: 'Kwi',
    may: 'Maj',
    jun: 'Cze',
    jul: 'Lip',
    aug: 'Sie',
    sept: 'Wrz',
    oct: 'Paź',
    nov: 'Lis',
    dec: 'Gru',
  },
  emptyTableAddNewRow: 'Jeśli chcesz je dodać, kliknij przycisk poniżej',
  add: 'Dodaj',
  confirmDialogHeader: 'Potwierdź czynność',
  sthWentWrong: 'Coś poszło nie tak',
  tryAgain: 'Spróbuj ponownie',
  soonToBeAdded: 'Zostanie dodane wkrótce!',
  more: 'więcej',
  heading: 'Tytuł',
  subheading: 'Podtytuł',
  normalText: 'Tekst',
  addressInformation: 'Dane adresowe',
  contactDetails: 'Dane kontaktowe',
  invoiceData: 'Dane do faktury',
  showDetails: 'Dodaj adres pacjenta',
  hideDetails: 'Ukryj adres pacjenta',
  uploadOrDrag: 'Prześlij lub przeciągnij i upuść plik',
  date: {
    range: {
      select: 'Wybierz zakres',
      currentMonth: 'Bieżący miesiąc',
      previousMonth: 'Poprzedni miesiąc ',
      last3Months: 'Ostatnie 3 miesiące',
      nextMonth: 'Następny miesiąc',
    },
  },
  therapist: 'Terapeuta:',
  office: 'Biuro:',
  patient: 'Pacjent:',
  dateSession: 'Data sesji:',
  sessionNotes: 'Notatki z sesji:',
  total: 'Razem',
  expand: 'Rozwiń',
  collapse: 'Zwiń',
  copyNumber: 'Skopiuj numer',
  copyEmail: 'Skopiuj email',
  sendSms: 'Wyślij SMS',
  sendEmail: 'Wyślij Email',
  close: 'Zamknij',
  refresh: 'Odśwież',
  privacyPolicy: 'Polityka prywatności',
  termsAndConditions: 'Regulamin',
  submit: 'Wyślij',
  acceptAll:
    'Oświadczam, że zapoznałem się z Regulaminem, Polityką prywatności oraz zawartymi w nich zasadami przetwarzania danych przez Swifter Software Sp. z o.o. oraz je akceptuję. ',
  generatedOn: 'Wygenerowano dnia: ',
  at: 'w',
  byDobryGabinet: 'przez DobryGabinet.com',
  page: 'Strona',
  pageReportTemplate: {
    singlePage: {
      singleRow:
        'Wyświetlam {rows} wpis na stronie {currentPage} z {totalPages} strony',
      manyRows:
        'Wyświetlam {rows} wpisów na stronie {currentPage} z {totalPages} strony',
      manyRowsPolish:
        'Wyświetlam {rows} wpisy na stronie {currentPage} z {totalPages} strony',
    },
    singleRow:
      'Wyświetlam {rows} wpis na stronie {currentPage} z {totalPages} stron',
    manyRows:
      'Wyświetlam {rows} wpisów na stronie {currentPage} z {totalPages} stron',
    manyRowsPolish:
      'Wyświetlam {rows} wpisy na stronie {currentPage} z {totalPages} stron',
  },
  markAsPaid: 'Oznacz jako opłacone',
  clickToMarkAsPaid: 'Kliknij, aby zmienić status wybranych spotkań',
  clear: 'Wyczyść',
  apply: 'Zastosuj',
  attachments: {
    title: 'Załączniki',
    deleteTitle: 'Usuwanie pliku:',
    deleteCaption: 'Czy na pewno chcesz usunąć ten plik?',
  },
  show: 'Pokaż',
  placeholderAll: 'Wszystkie możliwości',
  next: 'Dalej',
  back: 'Cofnij',
  open: 'Otwórz',
  branch: 'Oddział',
  consent: 'Wyrażam zgodę',
}
