module.exports = {
  all: 'Wszystkie faktury',
  newInvoice: 'Nowa faktura',
  synchronize: 'Synchronizuj dane',
  searchPlaceholder: 'Szukaj faktur',
  title: 'Faktury',
  invoice: 'Faktura',
  activateInvoicesDescription:
    'Aby uruchomić funkcję faktur, konieczne jest jej aktywowanie. Ta opcja jest dodatkowo płatna, a koszty zostaną uwzględnione w następnym rozliczeniu. Aktywacja faktur kończy okres darmowego testu i wymaga opłacenia obligatoryjnej faktury.',
  activateInvoices: 'Aktywuj faktury',
  actions: {
    download: 'Pobierz',
    downloadPdf: 'Pobierz PDF',
    edit: 'Edytuj fakturę',
    markAsPaid: 'Oznacz jako zapłaconą',
    markAsSent: 'Oznacz jako wysłaną',
    markAsUnpaid: 'Oznacz jako niezapłaconą',
    send: 'Wyślij fakturę',
    remove: 'Usuń fakturę',
    sentTo: 'Wysłana do',
    synchronize: 'Synchronizuj',
    fakturowniaPreview: 'Edytuj w Fakturowni',
  },
  currency: {
    EUR: '€',
    PLN: 'zł',
    USD: '$',
  },
  menu: {
    delete: 'Usuń',
    download: 'Pobierz',
    issueSimilar: 'Wystaw podobną',
    markAsSent: 'Oznacz jako wysłaną',
    open: 'Otwórz',
    sendInvoice: 'Wyślij fakturę',
  },
  penny: 'gr',
  settings: {
    billingInfo: 'Dane rozliczeniowe',
    selectOneOfTheOptions: 'Wybierz jedną z poniższych opcji',
    activateInvoicingMessage:
      'Jeśli chcesz aktywować dostęp do faktur, uzupełnij dane poniżej. Włączając tę funkcję, okres próbny zostanie zakończony i naliczona zostanie dodatkowa opłata za korzystanie z faktur.',
    addBillingInfoFirst:
      'Aby móc aktywować faktury, musisz najpierw dodać dane rozliczeniowe',
    readBeforeYouAEnableInvoices:
      'Włączenie faktur spowoduje naliczenie dodatkowych opłat zgodnie z naszym cennikiem. Korzystając z faktur, wyrażasz zgodę na te dodatkowe opłaty.',
    createInvoiceAccountForMe:
      'Kliknij tutaj, aby utworzyć nowe konto w Fakturowni',
    alreadyFakturowniaUser:
      'Jeśli już masz konto w Fakturowni, proszę podaj klucz API poniżej. Jeśli nie masz jeszcze konta, pozostaw to pole puste, a my założymy je za Ciebie i dostarczymy Ci dane do logowania.',
    goToBillingInfo: 'Przejdź do danych rozliczeniowych',
    messagePart1: 'Wprowadzone tutaj informacje automatycznie staną się',
    messagePart2: 'używanymi w sekcji „szczegóły sprzedawcy”.',
    messageStrong: 'danymi faktury',
    successfullyAddedBillingInfo: 'Dane rozliczeniowe zostały dodane',
    successfullyUpdatedBillingInfo: 'Dane rozliczeniowe zostały zaktualizowane',
    successfullyUpdated: 'Konfiguracja faktur została zaktualizowana',
    alreadyUsingFakturownia: {
      button: 'Już korzystam z Fakturowni',
      description:
        'Wybierz tę opcję jeśli masz już konto w Fakturowni. Będziesz mógł/-a połączyć wówczas swoje konto w DobrymGabinecie i Fakturowni. ',
    },
    activationDialog: {
      title: 'Aktywacja faktur',
      description:
        'Włączenie faktur spowoduje naliczenie dodatkowych opłat zgodnie z naszym cennikiem. Korzystając z faktur, wyrażasz zgodę na te dodatkowe opłaty.',
    },
    createNewAccountOnOurBehalf: {
      button: 'Utwórz nowe konto w Fakturowni',
      description:
        'Wybierz tę opcję jeśli nie masz jeszcze konta w Fakturowni. Założymy wówczas konto połączone z właścicielem ośrodka w DobrymGabinecie. ',
    },
    successfullyAddedInvoiceConfig: 'Konfiguracja faktur została dodana',
    successfullyUpdatedInvoiceConfig:
      'Konfiguracja faktur została zaktualizowana',
    yourApiKey:
      'To jest twój klucz API Fakturowni. Jeśli go zmienisz w Fakturowni, będziesz musiał także zaktualizować go tutaj.',
    subdomain: {
      warning: 'Uwaga! Nie możesz zmienić tej wartości później.',
    },
    yourFakturowniaPage: 'Twoja strona w Fakturowni',

    // Removal dialog
    caption:
      'Wybierz właściwą opcję. Pamiętaj, że zmiany mogą być nieodwracalne.',
    buttonCaption: 'Wybierz metodę usuwania: ',
    deletingWarning: 'Czy aby na pewno chcesz to zrobić? ',
    remove: 'Usuń konto',
    IAmDeletingAccount: "Wpisz: 'Usuwam konto'",
    IAmDeletingText: 'Usuwam konto',
    removalDialogHeader: 'Czy na pewno chcesz usunąć konto?',
    removeFromDatabaseOnlyButton: 'Usuń konfiguracje ustawień faktur',
    removeFromDatabaseOnlyHeader:
      'Usuń połączenie między DobrymGabinetem a Fakturownią',
    removeCompletelyHeader: 'Usuń wszystkie dane oraz konto w Fakturowni',
    removeFromDatabaseOnlyDescription:
      'Twoje dane i konto w Fakturowni zostaną zachowane, jednakże konto nie będzie już dostępne w Dobrym Gabinecie. Nadal będziesz mógł/mogła się logować do Fakturowni przy użyciu swoich danych do logowania. Istnieje możliwość przywrócenia połączenia między Dobrym Gabinetem a Fakturownią w przyszłości.',
    removeCompletelyButton:
      'Usuń konfiguracje ustawień faktur oraz zewnętrzne konto w Fakturowni',
    removeCompletelyDescription:
      'Twoje dane i konto w Fakturowni zostaną trwale usunięte. Również połączenie między Fakturownią a DobrymGabinetem zostanie usunięte. Jeśli na tym koncie w Fakturowni masz wystawione faktury, zalecamy ich archiwizację przed wykonaniem tego kroku.',
    removeDescription:
      'Aby potwierdzić czynność, wpisz "Usuwam konto" i kliknij przycisk "Usuń konto"',
    lookoutForEmail:
      'Wyglądaj za e-mailem od Fakturowni po usunięciu! Działanie to można odwrócić',
    apiConnection: {
      title: 'Połączenie z Fakturownią',
      descriptionNew:
        'Wprowadź tutaj unikalną nazwę, pod którą Twój gabinet lub ośrodek będzie dostępny w systemie Fakturownia. Na przykład, jeśli wybierzesz nazwę mojafirma", to stworzy to adres “mojafirma.fakturownia.pl”. Lub skopiuj swój adres URL z Fakturowni i wklej go tutaj.',
      description:
        'Jeśli masz już konto w fakturowni wpisz klucz API tutaj aby wystawiać faktury do tego konta. ',
      stepsTitle: 'Nie wiesz gdzie znaleźć klucz API?',
      step1: 'Zaloguj się do panelu Fakturowni, korzystając z adresu',
      step2:
        'Wybierz w prawym górnym rogu opcję <strong>"Ustawienia"</strong> (Settings)',
      step3:
        'W lewym menu wybierz <strong>"Integracja"</strong> (Integration) z listy opcji',
      step4:
        'W pierwszej linii, zlokalizuj opcję <strong>"Zobacz ApiTokeny"</strong> (Show ApiTokens)',
      step5: 'Skopiuj wygenerowany <strong>API TOKEN</strong>',
      step6:
        'Przejdź do powyższego pola i wklej skopiowany <strong>API TOKEN</strong>',
    },
    switchDescription: {
      title: 'Status faktur',
      description: 'Tutaj możesz włączyć lub wyłączyć faktury',
    },
  },
  sidebar: {
    create: {
      addNewInvoiceItem: 'Dodaj nową pozycję',
      buyerDetails: 'Dane nabywcy',
      currency: 'Waluta',
      dropLogo: 'Upuść logo tutaj',
      dueDate: 'Termin płatności',
      edit: '@:common.edit',
      enterManually: 'Wprowadź dane ręcznie',
      grossAmount: 'Wartość brutto',
      invoiceEntries: 'Pozycje na fakturze',
      invoiceNumber: 'Numer faktury',
      issueDate: 'Data wystawienia',
      itemName: 'Nazwa pozycji',
      nettoAmount: 'Wartość netto',
      notes: '@:common.comments',
      notesPlaceholder:
        'Sprzedawca zwolniony podmiotowo z podatku VAT od towarów i usług na podstawie Art.113 ust. 1 i 9 ustawy o VAT z dnia 11 marca 2004, Dz. U. z 2011 Nr 177 poz. 1054 z późniejszymi zmianami.',
      paidOn: 'Zapłacono',
      paymentMethod: 'Płatność',
      tooltip: 'Tu dodasz dane do faktury',
      quantity: 'Ilość',
      saleDate: 'Data sprzedaży',
      selectFromList: 'Wybierz z listy',
      sellerDetails: 'Dane sprzedawcy',
      supportsFormats: 'Obsługiwane formaty: JPG, PNG, Max rozmiar: 5MB',
      tax: 'VAT',
      taxAmount: 'Wartość VAT',
      taxNo: 'NIP',
      title: 'Wypisz fakturę',
      totalPriceGross: 'Suma brutto',
      totalPriceNet: 'Suma netto',
      totalPriceTax: 'Podatek',
      transactionDetails: 'Pozycje na fakturze',
      itemPrice: 'Cena / jdn',
      billingInfo: 'Zapisz dane rozliczeniowe',
      issue: 'Wystaw',
      clickToIssueInvoice: 'Kliknij, aby wystawić fakturę z podanymi danymi',
    },
    dialogs: {
      addMeetings: 'Dodaj spotkania',
      all: 'Wszystkie',
      cancel: '@:common.cancel',
      contractors: 'Przedsiębiorcy',
      fillIn: 'Wprowadź dane ręcznie w następnym kroku',
      from: '@:common.from',
      markAsPaid: '@:invoices.actions.markAsPaid',
      markAsPaidConfirm:
        'Czy na pewno chcesz oznaczyć fakturę jako zapłaconą, na kwotę {amount}zł?',
      markAsSent: '@:invoices.actions.markAsSent',
      markAsSentConfirm: 'Czy na pewno chcesz oznaczyć fakturę jako wysłaną?',
      markAsUnpaid: '@:invoices.actions.markAsUnpaid',
      markAsUnpaidConfirm:
        'Czy na pewno chcesz oznaczyć fakturę jako niezapłaconą?',
      markedAsPaid: 'Faktura została oznaczona jako zapłacona',
      markedAsSent: 'Faktura została oznaczona jako wysłana',
      removed: 'Faktura została usunięta',
      synced: 'Faktura została zsynchronizowana',
      markedAsUnpaid: 'Faktura została oznaczona jako niezapłacona',
      newClient: 'Nowy klient',
      noPaidMeetings: 'Brak zapłaconych spotkań w wybranym okresie',
      noUnpaidMeetings: 'Brak niezapłaconych spotkań w wybranym okresie',
      paid: '@:meetingHistoryTable.row.statuses.paid',
      patients: 'Pacjenci',
      searchContractor: 'Wyszukaj przedsiębiorcę',
      searchPatient: 'Wyszukaj pacjenta',
      select: 'Wybierz',
      selectMeetings: 'Wybierz spotkania, które chcesz dodać do faktury',
      selectRecipient: '@:invoices.sidebar.preview.selectInvoiceRecipient',
      selectRecipients: 'Wybierz odbiorców',
      selectYourMeetings: 'Wybierz swoje spotkania',
      sendACopyToMe: 'Wyślij kopię do mnie',
      sendInvoiceViaEmail: 'Wyślij fakturę mailem',
      unpaid: '@:meetingHistoryTable.row.statuses.unpaid',
      until: '@:common.until',
      sent: 'Wysłano fakturę',
      yesConfirm: 'Tak, potwierdzam',
      youMustSelectRecipientFirst: 'Musisz najpierw wybrać nabywcę faktury.',
      invoiceNumber: 'Faktura nr',
      customEmailInputPlaceholder: 'Lub wpisz adres email',
      send: 'Wyślij fakturę',
      customEmailAdded: 'Dodano adres email',
      customEmailAddedButNotSelected:
        'Dodano adres email, ale nie został wybrany, limit 5 adresów email został osiągnięty',
      paymentNotRequired: 'Płatność nie jest wymagana',
    },
    form: {
      created: 'Faktura została utworzona',
      billingInfoSaved: 'Dane rozliczeniowe zostały zapisane',
      errors: {
        invalidDate: 'Nieprawidłowa data',
        invalidKind: 'Nieprawidłowy rodzaj',
        noClient: 'Faktura musi posiadać odbiorcę',
        noClientName: 'Faktura musi posiadać nazwę odbiorcy',
        noClientStreet: 'Faktura musi posiadać adres odbiorcy',
        noClientPostCode: 'Faktura musi posiadać kod pocztowy odbiorcy',
        noClientCity: 'Faktura musi posiadać miasto odbiorcy',
        noClientTaxNo: 'Faktura musi posiadać NIP odbiorcy',
        noItems: 'Faktura musi zawierać przynajmniej jeden element',
        noName: 'Faktura musi mieć nazwę',
        noNumber: 'Faktura musi mieć numer',
        noPayment: 'Faktura musi posiadać metodę płatności',
        noClientTaxNoCEIDG:
          'Musisz podać NIP odbiorcy, aby pobrać dane z CEIDG',
      },
    },
    preview: {
      // seller & buyer
      address: '@:common.address',
      bank: 'Bank',
      nip: 'NIP',
      bankAccount: 'Konto bankowe',
      buyer: 'Nabywca',
      buyerDetails: '@:invoices.sidebar.create.buyerDetails',
      buyerName: 'Nazwa nabywcy',
      // buttons
      cancel: '@:common.cancel',
      city: '@:address.city',
      company: 'Firma',
      companyName: 'Nazwa firmy',
      clickToSelectRecipient: 'Kliknij, aby wybrać odbiorcę faktury',
      clickToIssueInvoice: 'Kliknij, aby wystawić fakturę z podanymi danymi',
      clickToClearInvoice:
        'Kliknij, aby wyczyścić fakturę i zacząć od początku',
      getDataFromCEIDG: 'Pobierz dane z CEIDG',
      // base info
      documentNumber: 'Faktura numer',
      dueDate: 'Termin płatności',
      email: 'Email',
      grossAmount: '@:invoices.sidebar.create.grossAmount',
      including: 'W tym',
      // currency
      inWords: 'Słownie',
      issue: 'Wystaw',
      issueDate: '@:invoices.sidebar.create.issueDate',
      item: 'Nazwa towaru / usługi',
      // table
      paymentTo: 'Termin płatności',
      lp: 'LP',
      name: 'Imię',
      fullName: 'Imię i nazwisko',
      netto: 'Cena netto',
      nettoAmount: '@:invoices.sidebar.create.nettoAmount',
      notes: '@:invoices.sidebar.create.notes',
      paidAmount: 'Kwota zapłacona',
      paidOn: '@:invoices.sidebar.create.paidOn',
      paymentMethod: '@:invoices.sidebar.create.paymentMethod',
      phone: '@:common.phoneNumber',
      postCode: '@:address.zipCode',
      privatePerson: 'Osoba prywatna',
      quantity: '@:invoices.sidebar.create.quantity',
      reset: 'Wyczyść',
      saleDate: '@:invoices.sidebar.create.saleDate',
      selectInvoiceRecipient: 'Wybierz odbiorcę faktury',
      seller: 'Sprzedawca',
      sellerDetails: '@:invoices.sidebar.create.sellerDetails',
      sellersSignature: 'Podpis sprzedawcy',
      send: 'Wyślij fakturę',
      street: '@:common.address',
      surname: 'Nazwisko',
      tax: 'VAT',
      taxAmount: '@:invoices.sidebar.create.taxAmount',
      taxNo: '@:invoices.sidebar.create.taxNo',
      title: 'Podgląd faktury',
      total: '@:common.total',
      totalDue: 'Do opłacenia',
      totalGrossPrice: 'Suma brutto',
      totalNetPrice: 'Suma netto',
      tooltip: 'Kliknij, aby zobaczyć podgląd faktury',
      unit: 'Jednostka',
      sentTo: 'Wysłana do: ',
      taxNoCantBeEmpty: 'Pole NIP nie może być puste',
    },
  },
  status: {
    issued: 'Wystawiona',
    paid: 'Opłacona',
    sent: 'Wysłana',
    unpaid: 'Nieopłacona',
  },
  table: {
    all: 'Wszystkie',
    brutto: 'Brutto',
    documentNumber: 'Nr dokumentu',
    dueDate: 'Termin płatności',
    empty:
      'Brak faktur w wybranym przedziale czasowym. Dodaj nową lub wybierz inny zakres.',
    emptyOnSearch: 'Nie znaleziono faktur odpowiadających Twoim kryteriom.',
    issueDate: 'Data wystawienia',
    issuedTo: 'Odbiorca',
    chosenSum: 'Suma zaznaczonych: ',
    netto: 'Netto',
    open: 'Wystawione',
    past: 'Opłacone',
    status: 'Status',
    tax: 'Podatek',
  },
}
