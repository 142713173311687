import { Context } from '@nuxt/types'
import { useContext } from '@nuxtjs/composition-api'
import type { User } from '@sentry/types'
import { client } from '~/api/setup'
import { getAuthToken } from '~/utils/token'
import { cache as djangoCache } from '~/apollo/djangoClient'
import { cache as hasuraCache } from '~/apollo/client'

export type UseContextReturn = ReturnType<typeof useContext>

export const logout = async (context: UseContextReturn | Context) => {
  await djangoCache.reset()
  await hasuraCache.reset()

  window.localStorage.clear()
  window.sessionStorage.clear()
  context.$auth.logout()
}

const setAxiosInterceptors = (context: UseContextReturn | Context) => {
  const { $auth } = context
  const jsonToken = getAuthToken($auth)
  client.interceptors.request.use((config) => {
    config.headers = {
      'Content-Type': 'application/json',
      Authorization: jsonToken ?? undefined,
    }
    return config
  })
}

export default function (ctx: Context) {
  const { $auth, $sentry } = ctx
  // @ts-ignore
  window.onNuxtReady(() => {
    setAxiosInterceptors(ctx)
    if (!$auth.loggedIn) {
      $sentry.setUser(null)
    } else {
      if (!$auth.user) return
      $sentry.setUser({
        id: $auth.user.sub ?? '',
        username: $auth.user.nickname ?? '',
        email: $auth.user.email ?? '',
      } as User)
    }
  })
}

type JWTToken = {
  exp: number
  iat: number
  sub: string
}

export function parseJwt(token: string): JWTToken {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}
