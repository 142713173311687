module.exports = {
  contactDetails: {
    title: 'Contact details',
    empty: 'No contact details',
    add: 'Add',
  },
  groupMembers: {
    title: 'Group members',
    empty: 'No group members',
    add: 'Add new',
  },
  coupleMembers: {
    title: 'Couple members',
    empty: 'No couple members',
  },
  coupleUpcomingMeeting: {
    title: 'Upcoming meetings',
    empty: 'No meetings',
  },
  coupleAttachments: {
    title: 'Attachments',
  },
  therapist: {
    title: 'Therapist',
  },
  upcomingMeetings: {
    title: 'Upcoming meetings',
    empty: 'No upcoming meetings',
    add: 'Schedule new',
  },
  history: {
    title: 'History',
    empty: 'No patient history',
    therapist: 'Therapist',
    added: 'Added:',
  },
  attachments: {
    title: 'Attachments',
    empty: 'No attachments',
    add: 'Add new',
  },
  gdpr: {
    title: 'GDPR',
    empty: 'No GDPR',
    add: 'Add new',
  },
  notifications: {
    title: 'Notifications',
    true: 'This patient will receive automatic notifications',
    false: 'Automatic notifications are disabled',
  },
  groupNotifications: {
    title: 'Notifications',
    true: 'This group will receive automatic notifications',
    false: 'Automatic notifications are disabled',
  },
  invoices: {
    title: 'Invoices & payments',
    empty: 'No invoices',
    add: 'Add new',
  },
}
