const {
  getEmptyTableMessage,
  getEmptyTableOnSearchMessage,
} = require('./utils')

module.exports = {
  all: 'All invoices',
  newInvoice: 'New invoice',
  synchronize: 'Synchronize invoices',
  searchPlaceholder: 'Search invoices',
  title: 'Invoices',
  invoice: 'Invoice',
  activateInvoicesDescription:
    'If you would like to activated the invoices feature you need to activate it first. This feature is additionally paid and the cost will be added to your next invoice. Activating the invoices feature ends the free trial period with a mandatory invoice to be paid.',
  activateInvoices: 'Activate invoices',
  actions: {
    download: 'Download',
    downloadPdf: 'Download PDF',
    edit: 'Edit invoice',
    markAsPaid: 'Mark as Paid',
    markAsSent: 'Mark as sent',
    markAsUnpaid: 'Mark as unpaid',
    remove: 'Remove invoice',
    send: 'Send invoice',
    sentTo: 'Sent to',
    synchronize: 'Synchronize',
    fakturowniaPreview: 'Edit in Fakturownia',
  },
  currency: {
    EUR: '€',
    PLN: 'zł',
    USD: '$',
  },
  menu: {
    delete: 'Delete',
    download: 'Download',
    issueSimilar: 'Issue similar',
    markAsSent: 'Mark as sent',
    open: 'Open',
    sendInvoice: 'Send invoice',
  },
  penny: 'gr',
  settings: {
    billingInfo: 'Billing information',
    selectOneOfTheOptions: 'Select one of the options below',
    activateInvoicingMessage:
      'If you would like to activate invoicing please add the relevant details below. Note that by adding this your trial period will be over and you will be charged an extra value for using invoices.',
    addBillingInfoFirst: 'You need to add your billing information first.',
    readBeforeYouAEnableInvoices:
      'Enabling the invoicing feature will result in additional charges in accordance with our pricing schedule. By enabling the invoicing feature, you give your consent to these additional charges.',
    createInvoiceAccountForMe: 'Click here to create a new Fakturownia account',
    alreadyFakturowniaUser:
      'If you already have an account with Fakturownia please provide API key below. If you don’t have one, leave the field blank and we will setup one for you on your behalf and provide you with login details.',
    goToBillingInfo: 'Go to billing information',
    messagePart1:
      'Please note that information provided here will automatically become the',
    messagePart2: 'used under “seller details” section.',
    messageStrong: ' invoice data ',
    successfullyAddedBillingInfo: 'Billing information has been added',
    successfullyUpdatedBillingInfo: 'Billing information has been updated',
    successfullyUpdated: 'Invoice configuration has been updated',
    alreadyUsingFakturownia: {
      button: "I'm already using Fakturownia",
      description:
        'Choose this option if you already have an account with Fakturownia. You will be able to connect your account in DobryGabinet with Fakturownia.',
    },
    createNewAccountOnOurBehalf: {
      button: 'Create new fakturownia account',
      description:
        "Choose this option if you don't have an account with Fakturownia yet. We will create an account for practice, and it will be linked to practice owner in DobryGabinet.",
    },
    // Removal dialog
    remove: 'Delete account',
    caption:
      'Select the appropriate option. Remember that changes may be irreversible.',
    buttonCaption: 'Choose deletion method: ',
    deletingWarning: 'Are you sure you want to proceed? ',
    IAmDeletingText: 'I am deleting account',
    IAmDeletingAccount: "Type: 'I am deleting account'",
    removeDescription: 'To proceed, click the "I am deleting account" button',
    lookoutForEmail:
      'Look out for the e-mail from Invoice after deletion! This action can be reversed.',
    removalDialogHeader: 'Fakturownia Account Deletion',
    removeFromDatabaseOnlyButton: 'Remove practice invoice config',
    removeFromDatabaseOnlyHeader:
      'Remove connection between DobryGabinet and Fakturownia',
    removeCompletelyHeader: 'Remove all the data and Fakturownia account',
    removeFromDatabaseOnlyDescription:
      'Your data and account in Fakturowni will be saved, but the account will no longer be available in DobryGabinet. You will still be able to log in to Fakturowni using your login data. There is a possibility to restore the connection between Dobrym Gabinecie and Fakturownią in the future. Your data and account in Fakturowni will be saved, but the account will no longer be available in Dobrym Gabinecie. You will still be able to log in to Fakturowni using your login data. There is a possibility to restore the connection between Dobrym Gabinecie and Fakturownią in the future.',
    removeCompletelyButton:
      'Remove practice invoice config and remote Fakturownia account',
    removeCompletelyDescription:
      'Your data and account in Fakturownia will be permanently deleted. The connection between Fakturownia and DobryGabinet will also be deleted. If you have issued any invoices on this account in Fakturownia, we recommend archiving them before performing this action.',
    yourApiKey:
      'This is your Fakturownia API key, if you change it in Fakturownia, you will need to update it here as well.',
    subdomain: {
      warning: 'Please note that this cannot be changed later',
    },
    activationDialog: {
      title: 'Activate invoicing',
      description:
        'By activating invoicing you agree to the additional charges in accordance with our pricing schedule. By enabling the invoicing feature, you give your consent to these additional charges.',
    },
    yourFakturowniaPage: 'Your page on Fakturownia',
    apiConnection: {
      title: 'Connection with Fakturownia',
      descriptionNew:
        'Enter the subdomain, which is the prefix in the address of your account in Fakturownia. For example, if your address is mypractice.fakturownia.pl, enter "mypractice" here. Or copy your Fakturownia URL and paste it here.',
      description:
        'If you already have an account in Fakturownia, enter your API key here to issue invoices to that account.',
      stepsTitle: 'Not sure where to find the API key?',
      step1: 'Log in to the Fakturownia dashboard using the address ',
      step2:
        'Select the option <strong>"Settings"</strong> in the upper right corner',
      step3:
        'From the left menu, choose <strong>"Integration"</strong> from the list of options',
      step4:
        'Locate the <strong>"Show ApiTokens"</strong> option in the first line',
      step5: 'Copy the generated <strong>API TOKEN</strong>',
      step6:
        'Go to the field above and paste the copied <strong>API TOKEN</strong>',
    },
    switchDescription: {
      title: 'Status of invoices',
      description: 'Here, you can turn invoices on or off.',
    },
  },
  sidebar: {
    create: {
      addNewInvoiceItem: 'Add new invoice item',
      buyerDetails: 'Buyer details',
      currency: 'Currency',
      dropLogo: 'Drop logo here',
      dueDate: 'Due date',
      edit: '@:common.edit',
      enterManually: 'Enter manually',
      grossAmount: 'Total gross',
      invoiceEntries: 'Invoice entries',
      invoiceNumber: 'Invoice number',
      issueDate: 'Issue date',
      itemName: 'Item name',
      nettoAmount: 'Total net',
      tooltip: 'Click to add invoice data',
      notes: 'Notes',
      notesPlaceholder:
        'Sprzedawca zwolniony podmiotowo z podatku VAT od towarów i usług na podstawie Art.113 ust. 1 i 9 ustawy o VAT z dnia 11 marca 2004, Dz. U. z 2011 Nr 177 poz. 1054 z późniejszymi zmianami.',
      paidOn: 'Paid on',
      paymentMethod: 'Payment method',
      quantity: 'Qty',
      saleDate: 'Sale date',
      selectFromList: 'Select from list',
      sellerDetails: 'Seller details',
      supportsFormats: 'Supported formats: JPG, PNG, Max size: 5MB',
      tax: 'VAT',
      taxAmount: 'VAT amount',
      title: 'Create Invoice',
      totalPriceGross: 'Total gross',
      totalPriceNet: 'Total net',
      totalPriceTax: 'Total tax',
      transactionDetails: 'Transaction details',
      itemPrice: 'Item price',
      billingInfo: 'Save billing information',
      issue: 'Issue',
      clickToIssueInvoice: 'Click here to issue this invoice like it is',
    },
    dialogs: {
      addMeetings: 'Add meetings',
      all: 'All',
      cancel: '@:common.cancel',
      contractors: 'Contractors',
      fillIn: 'Fill in the necessary data yourself',
      from: '@:common.from',
      markAsPaid: '@:invoices.actions.markAsPaid',
      markAsPaidConfirm:
        "Are you sure you want to mark this invoice as 'paid' in the amount of {amount} {currency}?",
      markAsUnpaid: '@:invoices.actions.markAsUnpaid',
      markAsUnpaidConfirm:
        "Are you sure you want to mark this invoice as 'unpaid'?",
      markedAsPaid: 'Invoice has been marked as paid',
      markedAsSent: 'Invoice has been marked as sent',
      removed: 'Invoice has been removed',
      synced: 'Invoice has been synchronized',
      markAsSent: '@:invoices.actions.markAsSent',
      markAsSentConfirm:
        "Are you sure you want to mark this invoice as 'sent'?",
      markedAsUnpaid: 'Invoice has been marked as unpaid',
      newClient: 'New client',
      noPaidMeetings: 'No paid meetings in selected period',
      noUnpaidMeetings: 'No unpaid meetings in selected period',
      paid: '@:meetingHistoryTable.row.statuses.paid',
      patients: 'Patients',
      searchContractor: 'Search contractor',
      searchPatient: 'Search patient',
      select: 'Select',
      selectMeetings: 'Select meetings to be added to invoice',
      selectRecipient: 'Select invoice recipient',
      selectRecipients: 'Select email recipients',
      selectYourMeetings: 'Select your meetings',
      sendACopyToMe: 'Send a copy to me',
      sendInvoiceViaEmail: 'Send invoice via email',
      unpaid: '@:meetingHistoryTable.row.statuses.unpaid',
      until: '@:common.until',
      yesConfirm: 'Yes, confirm',
      youMustSelectRecipientFirst: "You must select invoice's recipient first",
      invoiceNumber: 'Invoice no.',
      invoiceNr: 'Invoice number: ',
      customEmailInputPlaceholder: 'Or enter email address',
      send: 'Send invoice',
      customEmailAdded: 'Custom email has been added',
      customEmailAddedButNotSelected:
        "Custom email has been added, but hasn't been selected because you've reached the limit of 5 selected emails",
      sent: 'Invoice has been sent',
      paymentNotRequired: 'Payment not required',
    },
    form: {
      created: 'Invoice has been created',
      billingInfoSaved: 'Billing information has been saved',
      errors: {
        invalidDate: 'Invalid date',
        invalidKind: 'Invalid kind',
        noClient: 'Invoice must have a client',
        noClientName: 'Invoice must have a client name',
        noClientStreet: 'Invoice must have a client street',
        noClientPostCode: 'Invoice must have a client post code',
        noClientCity: 'Invoice must have a client city',
        noClientTaxNo: 'Invoice must have a client tax number',
        noItems: 'Invoice must have at least one item',
        noName: 'Invoice must have a name',
        noNumber: 'Invoice must have a number',
        noPayment: 'Invoice must have a payment method',
        noClientTaxNoCEIDG:
          'You should provide a tax number to retrieve it from CEIDG',
      },
    },
    preview: {
      // seller & buyer
      address: '@:common.address',
      bank: 'Bank',
      nip: 'NIP',
      bankAccount: 'Bank account',
      buyer: 'Buyer',
      buyerDetails: '@:invoices.sidebar.create.buyerDetails',
      buyerName: 'Buyer name',
      // buttons
      cancel: '@:common.cancel',
      city: '@:address.city',
      company: 'Company',
      companyName: 'Company name',
      clickToSelectRecipient: 'Click to select invoice recipient',
      clickToIssueInvoice: 'Click here to issue this invoice like it is',
      clickToClearInvoice: 'Click to clear this invoice and start over',
      getDataFromCEIDG: 'Retrieve data from CEIDG',
      // base info
      documentNumber: 'Invoice No.',
      dueDate: 'Due date',
      email: 'Email',
      grossAmount: '@:invoices.sidebar.create.grossAmount',
      // table
      including: 'Tax rate',
      // currency
      inWords: 'In words',
      issue: 'Issue',
      issueDate: '@:invoices.sidebar.create.issueDate',
      item: 'Item',
      lp: 'No.',
      name: 'Name',
      fullName: 'Full name',
      netto: 'Unit net price',
      nettoAmount: '@:invoices.sidebar.create.nettoAmount',
      notes: '@:invoices.sidebar.create.notes',
      paidAmount: 'Paid amount',
      paidOn: '@:invoices.sidebar.create.paidOn',
      paymentMethod: '@:invoices.sidebar.create.paymentMethod',
      phone: '@:common.phoneNumber',
      postCode: '@:address.zipCode',
      privatePerson: 'Private person',
      quantity: '@:invoices.sidebar.create.quantity',
      reset: 'Reset',
      paymentTo: 'Due date',
      saleDate: '@:invoices.sidebar.create.saleDate',
      selectInvoiceRecipient: 'Select invoice recipient',
      seller: 'Seller',
      sellerDetails: '@:invoices.sidebar.create.sellerDetails',
      sellersSignature: "Seller's signature",
      send: 'Send invoice',
      street: '@:common.address',
      surname: 'Surname',
      tax: '@:invoices.sidebar.create.tax',
      taxAmount: '@:invoices.sidebar.create.taxAmount',
      taxNo: 'VAT ID',
      title: 'Preview',
      total: '@:common.total',
      totalDue: 'Total due',
      tooltip: 'Click to see invoice preview',
      unit: 'Unit',
      sentTo: 'Sent to:',
      taxNoCantBeEmpty: 'tax No. cant be empty',
    },
  },
  status: {
    issued: 'Issued',
    paid: 'Paid',
    sent: 'Sent',
    unpaid: 'Unpaid',
  },
  table: {
    all: 'All',
    brutto: 'Brutto',
    documentNumber: 'Document no.',
    dueDate: 'Due date',
    empty: getEmptyTableMessage('invoices', 'Add invoices'),
    emptyOnSearch: getEmptyTableOnSearchMessage('invoices'),
    issueDate: 'Issue date',
    issuedTo: 'Issued to',
    chosenSum: 'Total of selected: ',
    netto: 'Netto',
    open: 'Open',
    past: 'Past',
    status: 'Status',
    tax: 'Tax',
  },
}
