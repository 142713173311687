module.exports = {
  searchForAvailableDeadline: 'Search for an available meeting time',
  searchParameters: 'Search parameters',
  availableSlotList: 'List of available slots',
  refresh: 'Refresh results',
  remainingSlots: 'Remaining Slots:',
  noBreak: 'No break',

  // inputs
  choose: {
    specialist: 'Choose specialist(s)',
    service: 'Choose service',
    dateRange: 'Choose date range',
    parameters: 'Choose meeting parameters',
    meetingBreak: 'Break after a meeting',
    showCanceledMeetingsAsFreeSlot: 'Canceled meetings as free slots',
    showAvailableSlotsEvery: 'Available slots every',
    showOnlyTherapistsAvailableInRegistration:
      'Only therapists available in online registration',
  },
  placeholders: {
    chooseSpecialist: 'Choose specialist',
    chooseService: 'Choose service',
  },

  // list
  list: {
    therapist: 'Therapist',
    date: 'Date',
    time: 'Time',
  },

  actions: {
    choose: 'Choose',
    next: 'Show another results',
    show: 'Show',
    openMeeting: 'Open the window for creating a new meeting',
  },

  table: {
    therapist: 'Therapist',
    date: 'Date',
    time: 'Time',
    service: 'Service',
    empty:
      'Change or select at least one specialist or service to see the results.',
  },
}
