module.exports = {
  couple: 'couple',
  therapist: 'Therapist',
  room: 'Room',
  paidOn: 'Paid on',
  unpaid: 'Unpaid',
  hasBeen: 'Has been',
  locked: 'locked',
  shifted: 'shifted | and shifted',
  hasNote: 'Has a note',
  hasSecretNote: 'Has a secret note',
  noP24Config:
    'The registration procedure for Przelewy24 has not been completed.',
}
