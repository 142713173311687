import Vue from 'vue'
import Tooltip from 'primevue/tooltip'
import BadgeDirective from 'primevue/badgedirective'
import { uniq } from 'lodash'
import { currentUserRoles, currentEmployee, Role } from '~/composable/userData'

Vue.directive('badge', BadgeDirective)

Vue.directive('tooltip', Tooltip)
Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    // @ts-ignore
    el.clickOutsideEvent = function (event: FocusEvent) {
      if (
        !(el === event.target || el.contains(event.target as unknown as Node))
      ) {
        // @ts-ignore
        vnode.context[binding.expression](event)
      }
    }
    // @ts-ignore
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    // @ts-ignore
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

// --------------------------------- CHECKING ROLE PERMISSIONS ---------------------------------

// TODO: replace with a permissions file or endpoint with permissions Object, just PoC right now.
export const permissions = {
  canAddEmployee: [Role.Finance, Role.Admin, Role.Technical, Role.Reception],
  canEditUser: [Role.Admin, Role.Reception],
  canEditOtherEmployees: [Role.Admin, Role.Reception],
  canResendInvitation: [Role.Admin, Role.Reception],
  canDownloadReport: [Role.Admin, Role.Reception],
  canUseFakturownia: [Role.Admin, Role.Reception],
} as const

export type Permission = keyof typeof permissions

export const checkIfUserHasPermission = (
  permissionsToCheck: Permission[]
): boolean => {
  if (!currentUserRoles?.value?.length || !permissionsToCheck.length)
    return true

  const rolesToCheck: Role[] = []

  permissionsToCheck.forEach((permission) => {
    rolesToCheck.push(...permissions[permission])
  })

  return uniq(rolesToCheck).some((role) =>
    currentUserRoles.value.includes(role)
  )
}

Vue.directive('can', {
  inserted: (el, binding, vnode) => {
    const permissionsToCheck: Permission[] = binding.value

    if (checkIfUserHasPermission(permissionsToCheck)) return

    // @ts-ignore
    vnode.elm.style.display = 'none'
  },
  update: (el, binding, vnode) => {
    const permissionsToCheck: Permission[] = binding.value

    if (checkIfUserHasPermission(permissionsToCheck)) return

    // @ts-ignore
    vnode.elm.style.display = 'none'
  },
})

// -----------------------------------------------------------------------------

// ------------------ CHECKING IF LOGGED EMPLOYEE CAN SEE ELEMENT --------------

export const employeeCanSeeElement = (
  employeeIdsToCheck: (number | string | undefined)[]
): boolean => {
  if (!currentEmployee.value || !employeeIdsToCheck.length) return true

  return employeeIdsToCheck.some(
    (eitc) => Number(eitc) === currentEmployee.value?.id
  )
}

Vue.directive('myself', {
  inserted: (el, binding, vnode) => {
    const employeeIdsToCheck: number[] = binding.value

    if (employeeCanSeeElement(employeeIdsToCheck)) return

    // @ts-ignore
    vnode.elm.style.display = 'none'
  },
})

// -----------------------------------------------------------------------------

// --------- CHECKING IF LOGGED EMPLOYEE AND ADMIN CAN SEE ELEMENT --------------

Vue.directive('myself-admin', {
  inserted: (el, binding, vnode) => {
    const employeeIdsToCheck: number[] = binding.value

    if (
      employeeCanSeeElement(employeeIdsToCheck) ||
      checkIfUserHasPermission(['canDownloadReport'])
    )
      return

    // @ts-ignore
    vnode.elm.style.display = 'none'
  },
  update: (el, binding, vnode) => {
    const employeeIdsToCheck: number[] = binding.value

    if (
      employeeCanSeeElement(employeeIdsToCheck) ||
      checkIfUserHasPermission(['canDownloadReport'])
    )
      return

    // @ts-ignore
    vnode.elm.style.display = 'none'
  },
})

Vue.directive('clipboard-paste', {
  inserted: (el, binding) => {
    const handlePaste = (e: ClipboardEvent) => {
      e.preventDefault()
      // @ts-ignore
      const clipboardData = e.clipboardData || window.clipboardData

      const pastedText = clipboardData.getData('text')

      binding.value(pastedText)
    }

    document.addEventListener('paste', handlePaste)
  },
})
