module.exports = {
  services: 'Usługi',
  name: '@:common.name',
  addService: 'Dodaj usługę',
  saveService: 'Zapisz usługę',
  selectDuration: 'Wybierz czas trwania',
  selectAssigned: 'Wpisz aby wybrać terapeutę...',
  deleteService: 'Czy na pewno chcesz usunąć tę usługę?',
  deleteServices: 'Czy na pewno chcesz usunąć te usługi?',
  price: 'Cena',
  duration: 'Czas trwania',
  comments: 'Uwagi',
  assignedTo: '@:groups.assignedTo',
  serviceAssignedTo: 'Usługa przypisana do',
  availableOnline: 'Online',
  availableInRegistration: 'Rejestracja',
  isThisServiceAvailableOnline:
    'Ta usługa jest dostępna dla spotkań wirtualnych',
  isThisServiceAvailableInOnlineRegistration:
    'Ta usługa jest dostępna w rejestracji online',
  searchPlaceholder: 'Nazwa usługi, cena',
  branch: 'Oddział',
  table: {
    selectPracticeBranch: '@:common.selectPracticeBranch',
    empty: 'Wygląda na to, że nie masz jeszcze żadnych usług.',
    emptyOnSearch: 'Nie znaleziono usług odpowiadających Twoim kryteriom.',
  },
  placeholders: {
    name: 'Terapia',
    comments: 'Terapia długoterminowa',
  },
  tooltips: {
    clickToRemoveService: 'Kliknij, aby usunąć usługę',
    clickToEditService: 'Kliknij, aby edytować usługę',
    thisServiceIsOnline: 'Ta usługa jest oferowana on-line/zdalnie',
    serviceIsAvailableInRegistration:
      'Ta usługa jest dostępna w rejestracji online',
    serviceIsNotAvailableInRegistration:
      'Ta usługa jest niedostępna w rejestracji online',
  },
}
