import { provide } from '@vue/composition-api'
import { ApolloClients } from '@vue/apollo-composable'
import { Context } from '@nuxt/types'
import { buildApolloClient } from '~/apollo/client'
import { buildDjangoApolloClient } from '~/apollo/djangoClient'

export default function (context: Context) {
  const { app } = context
  app.setup = () => {
    provide(ApolloClients, {
      default: buildApolloClient(app.context),
      django: buildDjangoApolloClient(app.context),
    })
  }
}
