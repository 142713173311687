module.exports = {
  age: 'Age:',
  isSince: 'In therapy since:',
  meetingsHeld: 'Concluded meetings:',
  therapist: 'Therapist',
  therapists: 'Therapists',
  searchTherapists: 'Search therapist…',
  patientReceiveNotify: 'Patient will receive automatic notifications',
  patientNotReceiveNotify: 'Patient will NOT receive automatic notifications',
  coupleReceiveNotify: 'Couple will receive automatic notifications',
  coupleNotReceiveNotify: 'Couple will NOT receive automatic notifications',
  therapyFinishedOn: 'Therapy finished on:',
  patientNoPhoneNumber:
    'To enable automatic sms notifications patient phone number must be provided',
  coupleNoPhoneNumber:
    "To enable automatic sms notifications couple's patients phone number must be provided",
  smsAll: 'SMS all members',
  emailAll: '@ Email all members',
  reactivateTherapy: 'Reactivate therapy',
  scheduleMeeting: 'Schedule meeting',
  therapyFinished:
    'This therapy has finished! Reactivate it to schedule new meetings.',
  prev: 'Prev',
  next: 'Next',
  belongsTo: 'Belongs to:',
  year: '(y)',
  month: '(m)',
  week: '(w)',
  description: 'Description:',
  changeDescription: 'Change',
  saveDescription: 'Save',
  cancelDescription: 'Cancel',
}
