module.exports = {
  day: 'Dzień',
  month: 'Miesiąc',
  week: 'Tydzień',
  today: 'Dzisiaj',
  newMeeting: 'Nowe spotkanie',
  defaultView: 'Widok domyślny',
  availabilityView: 'Widok dostępności',
  roomView: 'Widok gabinetów',
  serviceView: 'Widok usług',
  roomTimelineView: 'Oś czasu gabinetów',
  therapistTimelineView: 'Oś czasu terapeutów',
  removeSingleMeeting: 'Usuń to spotkanie',
  removeSeriesOfMeetings: 'Usuń serię spotkań',
  removeP24EventWarn:
    'To spotkanie ma co najmniej jedną transakcję Przelewy24. Przed kontynuowaniem, sprawdź ich status. Jeśli chcesz zwrócić pieniądze pacjentowi, przed usunięciem tego spotkania kliknij przycisk zwrotu i poczekaj na potwierdzenie',
  show: 'Pokaż',
  addNote: 'Dodaj notatkę',
  noNoteHasBeenAdded: 'Do tego spotkania nie dodano jeszcze notatki',
  noSecretNoteHasBeenAdded:
    'Do tego spotkania nie dodano jeszcze sekretnej notatki',
  secretNoteNotAvailable: 'Sekretna notatka nie jest dla ciebie dostępna',
  meetingIsPartOfSeries: 'To spotkanie jest częścią serii spotkań',
  returnPayment: 'Zwróć środki',
  sureToCreateRefund: 'Czy na pewno chcesz dokonać zwrotu?',
  paidOnline: 'Zapłacono online',
  awaitForVerify: 'Oczekiwanie na potwierdzenie transakcji',
  awaitForRefund: 'Oczekiwanie na potwierdzenie zwrotu transakcji',
  refunded: 'Płatność została zwrócona',
  unpaidOnline: 'Transakcja nie została opłacona',
  otherStatus: 'Nieznany status',
  meetingDetails: {
    title: 'Szczegóły spotkania',
    showPatientData: 'Pokaż dane pacjenta',
    locked:
      'To spotkanie zostało już zmienione indywidualnie (niezsynchronizowane z serią spotkań)',
    shifted:
      'To spotkanie zostało już przesunięte operacją przesunięcia serii spotkań (niezsynchronizowane z serią spotkań)',
  },
  doYouWantToRemoveThisMeeting: 'Czy chcesz usunąć to spotkanie?',
  doYouWantToRemoveWholeSeries:
    'Chcesz usunąć całą serię spotkań, czy tylko to spotkanie?',
  changeMeetingToSeries: 'Zamień spotkanie w serię spotkań',
  form: {
    meetingType: 'Typ spotkania',
    newMeeting: 'Nowe spotkanie',
    meetingWithPatient: 'Sesja terapii indywidualnej',
    groupMeeting: 'Sesja terapii grupowej',
    pairMeeting: 'Sesja terapii par',
    meetingWithoutPatient: 'Spotkanie bez pacjentów',
    time: 'Godzina',
    duration: 'Długość',
    date: 'Data',
    selectPatient: 'Wybierz pacjenta',
    selectService: 'Wybierz usługę',
    service: 'Usługa',
    selectGroup: 'Wybierz grupę',
    selectPair: 'Wybierz parę',
    selectRoom: 'Wybierz pomieszczenie',
    room: 'Pokój',
    patient: 'Pacjent',
    pair: 'Para | Pary',
    selectTherapist: 'Wybierz terapeutę | Wybierz terapeutów',
    therapists: 'Terapeuta',
    therapist: 'Terapeuta | Terapeuci',
    searchPatient: 'Znajdź pacjenta',
    searchService: 'Znajdź usługę',
    searchCouple: 'Znajdź parę',
    searchGroup: 'Znajdź grupę',
    repeatingMeeting: 'To spotkanie się powtarza',
    sendSms: "Wyślij SMS'a po zaplanowaniu spotkania",
    addService: '@:services.addService',
    addPatient: '@:patients.addPatient',
    addCouple: '@:patients.couples.addCouple',
    addGroup: '@:groups.addGroup',
    meetingName: 'Nazwa spotkania',
    presence: 'Obecność',
    payment: 'Płatność',
    patientPaid: 'Opłacone',
    paymentMethod: 'Metoda płatności',
    paymentDate: 'Data płatności',
    note: 'Notatka',
    secretNote: 'Sekretna notatka',
    editNote: 'Edytuj notatkę',
    saveNote: 'Zapisz notatkę',
  },
  tooltips: {
    clickToSeeCoupleDetails: 'Kliknij, aby zobaczyć szczegóły pary',
    clickToSeeDetails: 'Kliknij, aby zobaczyć szczegóły pacjenta',
    clickToSendSMSFromModal: 'Kliknij, aby wysłać wiadomość SMS',
    clickToRemoveMeeting: 'Kliknij, aby usunąć spotkanie',
    clickToSeeSessionDetails: 'Kliknij, aby zobaczyć szczegóły spotkania',
    clickToSeeSessionDetailsDisabled:
      'Szczegóły spotkania widoczne tylko dla terapeuty prowadzącego',
    noteIsBeingEdited: 'Notatka nie została zapisana',
    snapDuration: 'Dostosuj podział osi czasu\ndla przeciąganych spotkań',
    notCompatibleRoom: 'Dodaj spotkanie do pokoju wykluczonego z tej usługi',
    notAvailableRoom:
      'Ten pokój jest zajęty w wybranym terminie przez innego terapeutę',
  },
  editDialog: {
    // titles
    editMeeting: 'Edytuj spotkanie',
    editSeriesOfMeetings: 'Edytuj serię spotkań',
    editForceSeriesOfMeetings: 'Nadpisz serię spotkań',
    editForceSeriesOfMeetingsConfirm: 'Tak, potwierdzam',
    shiftMeeting: 'Przesuń spotkanie',
    shiftSeriesOfMeetings: 'Przesuń dzień serii spotkań',
    // descriptions
    editMeetingDesc: `Zmień tylko to spotkanie (zmiana indywidualna).

    Spotkanie nie będzie zmienione poprzez edycję lub przesunięcie serii spotkań w przyszłości.`,
    editSeriesOfMeetingsDesc: `Zmień to i wszystkie przyszłe spotkania w tej serii spotkań - z wyjątkiem spotkań, które zostały zmienione indywidualnie.

    Zmianie nie podlegają spotkania przesunięte za pomocą opcji przesuń serię spotkań.`,
    editForceSeriesOfMeetingsDesc: `Zmień to i wszystkie przyszłe spotkania w tej serii spotkań - uwzględniając spotkania zmienione indywidualnie.

    Zmianie nie podlegają spotkania przesunięte za pomocą opcji przesuń serię spotkań.`,
    shiftMeetingDesc:
      'To spotkanie nie zostanie zmienione w przyszłości poprzez edycje lub przesunięcie serii spotkań (zmiana indywidualna)',
    shiftSeriesOfMeetingsDesc: `To spotkanie i wszystkie przyszłe spotkania z tej serii, w danym dniu tygodnia, zostaną przesunięte - z wyjątkiem spotkań, które zostały zmienione indywidualnie.

    Niemożliwym będzie edycja przesuniętych spotkań poprzez opcję nadpisania serii spotkań`,
    warningDesc:
      'To spotkanie i wszystkie przyszłe spotkania z tej serii zostaną zastąpione wraz ze spotkaniami zmienionymi indywidualnie',
  },
}
