import { ErrorMap } from '~/types/Graphql'

const map: ErrorMap = {
  error: 'Coś poszło nie tak',
  // MUTATIONS
  AddPeriodicEvent: 'Nie udało się utworzyć serii spotkań',
  AddSingleEvent: 'Nie udało się utworzyć spotkania',
  EditEvent: 'Nie udało się zaktualizować spotkania',
  CreateEmployee: 'Nie udało się utworzyć pracownika',
  EditEmployee: 'Nie udało się zaktualizować pracownika',
  // AddGroup: 'Nie udało się utworzyć grupy',
  // AddGroupMembers: 'Nie udało się dodać członków grupy',
  // DeactivateGroup: 'Nie udało się dezaktywować grupy',
  AddCouple: 'Nie udało się utworzyć par',
  AddPatient: 'Nie udało się utworzyć pacjenta',
  EditPatient: 'Nie udało się zaktualizować pacjenta',
  AddPatientPsychotherapyNotes:
    'Nie udało się zaktualizować notatek psychoterapii pacjenta',
  EditPatientPsychotherapyNotes:
    'Nie udało się zaktualizować notatek psychoterapii pacjenta',
  AddPatientSpeechTherapyNotes:
    'Nie udało się zaktualizować notatek terapii logopedycznej pacjenta',
  EditPatientSpeechTherapyNotes:
    'Nie udało się zaktualizować notatek terapii logopedycznej pacjenta',
  CreateGuardian: 'Nie udało się utworzyć opiekuna',
  DeactivateGuardian: 'Nie udało się dezaktywować opiekuna',
  EditGuardian: 'Nie udało się zaktualizować opiekuna',
  EditPractice: 'Nie udało się zaktualizować gabinetu',
  AddRoom: 'Nie udało się utworzyć pokoju',
  EditRoom: 'Nie udało się zaktualizować pokoju',
  CreateService: 'Nie udało się utworzyć usługi',
  EditService: 'Nie udało się zaktualizować usługi',
  DeactivateServices: 'Nie udało się dezaktywować usług',
  EditUserSettings: 'Nie udało się zaktualizować ustawień użytkownika',
  ConfirmMeetingRequest:
    'Nie udało się potwierdzić wizyty. Wybrany teraupeuta ma już o tej godzinie spotkanie, lub pokój jest zajęty',
  // QUERIES
  GetEvent: 'Nie udało się pobrać spotkania',
  GetEvents: 'Nie udało się pobrać spotkań',
  GetPaginatedEvents: 'Nie udało się pobrać spotkań',
  GetEmployeesTable: 'Nie udało się pobrać pracowników',
  GetGroups: 'Nie udało się pobrać grup',
  GetCouples: 'Nie udało się pobrać par',
  GetPatients: 'Nie udało się pobrać pacjentów',
  GetPatientDetails: 'Nie udało się pobrać szczegółów pacjenta',
  GetCurrentPracticeDetails: 'Nie udało się pobrać szczegółów gabinetu',
  GetRoomsList: 'Nie udało się pobrać pokoi',
  GetServices: 'Nie udało się pobrać usług',
}

export default map
