module.exports = {
  addQuickNote: 'Dodaj szybką notatkę',
  createDocument: 'Stwórz dokument dla pacjenta',
  title: 'Historia spotkań',
  couplesTitle: 'Historia',
  downloadNotes: 'Pobierz notatki',
  downloadReport: 'Naciśnij, aby pobrać raport PDF',
  from: 'Od',
  until: 'Do',
  noSessions:
    'Brak spotkań w wybranym okresie. Dodaj spotkanie w kalendarzu, aby je tutaj zobaczyć.',
  noSessionsContinuing:
    'Tutaj zobaczysz tylko historyczne spotkania. Nadchodzące spotkania widoczne są po prawej stronie.',
  date: 'Data',
  service: 'Usługa',
  therapist: 'Terapeuta',
  actions: 'Akcje',

  filters: {
    date: 'Data',
    service: 'Usługa',
    status: 'Status',
    therapist: 'Terapeuta',
    note: 'Notatka',
    options: {
      clear: 'Wyczyść',
      asc: 'Rosnąco',
      desc: 'Malejąco',
      withNotes: 'Z notatkami',
      withoutNotes: 'Bez notatek',
      withAttachments: 'Z załącznikiem',
      withoutAttachments: 'Bez załącznika',
    },
    placeholders: {
      service: 'Wpisz nazwę usługi',
      therapist: 'Wpisz imię terapeuty',
      status: 'Wybierz status',
    },
  },
  row: {
    quickNote: 'Szybka notatka',
    document: 'Dokument',
    statuses: {
      paid: 'Opłacone',
      unpaid: 'Do opłacenia',
      cancelled: 'Odwołane',
      patientCancelledPayable: 'Odwołane, do opłacenia',
      patientCancelledNotPayable: 'Odwołane, płatność niewymagana',
      meetingCancelledByMe: 'Odwołane przeze mnie',
    },
    options: {
      download: 'Pobierz',
      edit: 'Edytuj',
      markAsRead: 'Zaznacz jako opłacone',
      issueInvoice: 'Wystaw fakturę',
    },
  },
  tooltips: {
    download: 'Naciśnij, aby pobrać notatki z wybranego okresu',
    filter: 'Naciśnij, aby filtrować notatki',
    noSessions: 'Nie ma sesji z których można pobrać notatki',
    aNoteHasBeenAddedToThisMeeting: 'Do tego spotkania została dodana notatka',
    clickToOpenOptions: 'Kliknij, aby zobaczyć opcje',
  },
  files: {
    txtName: 'Sesja - notatka',
    singleZipName: 'Notatka_z_sesji_pacjenta.zip',
    multipleZipName: 'Wszystkie_notatki_z_sesji_pacjenta.zip',
  },
}
