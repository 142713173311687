module.exports = {
  paymentOnline: 'Online payment at the time of booking',
  paymentInPerson: 'Payment in person at the practice',
  configUpdated: 'Payment config updated',

  description: {
    bottom:
      'When booking online, select at least one payment method available to patients.',
    description: 'Payment at booking',
    onlineDescription:
      'Available payment methods during online registration will depend on the configuration in the Przelewy24 panel',
  },

  radioOptions: {
    cash: 'Cash',
    card: 'Card payment',
    blik: 'BLIK on the phone',
    other: 'Other',
    przelewy24: 'Przelewy24',
    bankTransfer: 'Bank Transfer',
  },
}
