module.exports = {
  patient: 'Pacjent | Pacjenci',
  addPatient: 'Dodaj pacjenta',
  patientData: 'Dane pacjenta',
  invoiceData: 'Dane do faktury',
  basicInfo: 'Podstawowe informacje',
  assignedTo: '@:groups.assignedTo',
  typeTherapistToSelect: 'Wybierz terapeutę',
  createPatient: 'Utwórz pacjenta',
  addPatientData: 'Podaj dane pacjenta',
  addPatientAddress: 'Dodaj adres pacjenta',
  selectPatient: 'Wybierz pacjenta | Wybierz pacjentów',
  searchPlaceholder: 'Wpisz imię lub nazwisko pacjenta',
  patientInvoiceDataUpdated: 'Dane do faktury zaktualizowane',
  table: {
    empty:
      'Brak pacjentów. Aby dodać nowego pacjenta kliknij przycisk na górze',
    emptyOnSearch: 'Nie znaleziono pacjentów odpowiadających Twoim kryteriom.',
    tabs: {
      active: '@:common.active',
      inactive: '@:common.inactive',
      all: 'Wszyscy',
      couples: 'Pary',
      groups: 'Grupy',
      assignedTo: '@:common.assignedTo',
      unassigned: 'Pozostali',
    },
  },
  couples: {
    addCouple: 'Dodaj parę',
    coupleName: 'Nazwa pary',
    assignedTo: 'Para przypisana do',
    typePatientNameToSelect: 'Podaj nazwę pacjenta',
    typeTherapistNameToSelect: 'Podaj nazwę terapeuty',
    phoneNumber: '@:common.phoneNumber',
    patients: 'Pacjenci',
    searchPlaceholder: 'Wyszukaj pary',
    finishedAt: 'Zakończono terapię o',
    showDeactivatedCouples: 'Pokaż również pary, które zakończyły terapię',
    table: {
      empty: 'Brak par. Aby dodać nową parę kliknij przycisk na górze.',
      emptyOnSearch: 'Nie znaleziono par odpowiadających Twoim kryteriom.',
    },
  },
  details: {
    addQuickNote: 'Dodaj szybką notatkę',
    createDocument: 'Stwórz dokument dla pacjenta',
    history: '@:groups.cards.history',
    contactDetails: '@:groups.cards.contactDetails',
    groupMembers: '@:groups.cards.groupMembers',
    upcomingMeetings: '@:groups.cards.upcomingMeetings',
    attachments: '@:groups.cards.attachments',
    gdpr: '@:groups.cards.gdpr',
    notifications: '@:groups.cards.notifications',
  },
  guardians: {
    guardian: 'Opiekun | Opiekunowie',
    patientGuardians: 'Opiekunowie pacjenta',
    addGuardian: 'Dodaj nowego opiekuna',
    table: {
      empty:
        'Brakuje opiekunów, proszę dodaj używając przycisku Dodaj opiekuna.',
      emptyOnSearch:
        'Nie znaleziono opiekunów odpowiadających Twoim kryteriom.',
    },
    deleteGuardian: 'Czy na pewno chcesz usunąć opiekuna {name}?',
  },
  tabs: {
    meetingHistory: '@:groups.tabs.meetingHistory',
    psychotherapy: 'Psycho-log/-terapeuta',
    speechTherapy: 'Logopeda',
    si: 'Terapeuta SI',
    educator: 'Pedagog',
    patientData: 'Dane',
    attachments: 'Załączniki',
    gdpr: 'RODO',
    gdprDoc: 'dokumenty RODO',
    invoices: 'Faktury i płatności',
  },
  menu: {
    finishTherapy: '@:groups.menu.finishTherapy',
    reactivateTherapy: 'Odnów terapię',
    therapyIsFinished: 'Terapia jest zakończona',
    history: '@:groups.menu.history',
    delete: '@:common.delete',
    confirmDelete: 'Czy na pewno chcesz usunąć tego pacjenta?',
  },
  forms: {
    psychotherapy: {
      title: 'Notatki psychologa / psychoterapeuty',
      occupation: 'Obecne zajęcie',
      medicalHistory: 'Historia choroby',
      diagnosis: 'Rozpoznanie',
      triggerForTherapy: 'Powody rozpoczęcia terapii',
      expectations: 'Oczekiwania pacjenta, przewidywana długość terapii',
      otherNotes: 'Inne',
    },
    speechTherapy: {
      title: 'Notatki logopedy',
      generalInfo: 'Informacje ogólne',
      generalAim: 'Cel wizyty',
      medicalHistory: '@:patients.forms.psychotherapy.medicalHistory',
      speechExamination: 'Badanie logopedyczne',
      articulationExamination: 'Badania artykulacji',
      diagnosisTherapyPlan: 'Diagnoza i plan terapii',
      otherNotes: '@:patients.forms.psychotherapy.otherNotes',
    },
    si: {
      title: 'Notatki terapeuty SI',
      generalInfo: 'Informacje ogólne',
      siDiagnosis: 'Diagnoza SI',
      therapyHints: 'Wskazówki do terapii i zalecenia',
      sensoryDiet: 'Dieta sensoryczna',
      generalAim: 'Cel wizyty',
      medicalHistory: '@:patients.forms.psychotherapy.medicalHistory',
      diagnosisTherapyPlan: 'Diagnoza i plan terapii',
      otherNotes: '@:patients.forms.psychotherapy.otherNotes',
    },
    educator: {
      title: 'Notatki pedagoga',
      generalInfo: 'Informacje ogólne',
      generalAim: 'Cel wizyty',
      medicalHistory: '@:patients.forms.psychotherapy.medicalHistory',
      diagnosisTherapyPlan: 'Diagnoza i plan terapii',
      otherNotes: '@:patients.forms.psychotherapy.otherNotes',
    },
  },
  finishTherapyDialog: {
    title: 'Zakończ terapię',
    caption1: 'Na pewno chcesz zakończyć terapię tego pacjenta?',
    caption2:
      'Wszystkie dane zostaną zapisane, nadchodzące spotkania zostaną dezaktywowane (zaczynając od jutra).',
    caption2InMeeting:
      'Wszystkie dane zostaną zapisane, nadchodzące spotkania zostaną dezaktywowane (po tym spotkaniu).',
  },
  reactivateTherapyDialog: {
    title: 'Odnów terapię',
    caption1: 'Chcesz reaktywować terapię dla tego pacjenta?',
    caption2:
      'Pacjent będzie z powrotem dostępny przy tworzeniu spotkań oraz na liście aktywnych klientów.',
  },
  crumbs: {
    patients: 'Pacjenci',
  },
  actions: {
    send: 'Wyślij zgodę RODO',
  },
}
